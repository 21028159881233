export const IdController = () => {
  const generate = stateArray => {
    console.log("here bitch", stateArray);
    if (stateArray.length > 0) {
      let id = stateArray[stateArray.length - 1].id;
      return id + 1;
    } else {
      return 1;
    }
  };
  return { generate };
};

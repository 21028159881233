import Vue from "vue";
import Vuex from "vuex";
import http from "../../http";
Vue.use(Vuex);

export default {
  state: {
    jobs: [],
    extraFiles: [],
    extraFilesComment: "",
    roomType: [],
    roomOptions: [],
    propertyStyle: {},
    clientInfo: {},
    roomReferences: [],
    steps: [],
    selectedPlan: {}
  },
  getters: {
    jobs: state => state.jobs,
    selectedPlan: state => state.selectedPlan,
    extraFiles: state => state.extraFiles,
    extraFilesComment: state => state.extraFilesComment,
    clientInfo: state => state.clientInfo,
    roomType: state => state.roomType,
    roomOptions: state => state.roomOptions,
    propertyStyle: state => state.propertyStyle,
    roomReferences: state => state.roomReferences,
    steps: state => state.steps
  },
  mutations: {
    SELECT_PLAN(state, plan) {
      state.selectedPlan = plan;
    },
    SELECT_STYLE(state, style) {
      state.propertyStyle = style;
    },
    SET_REFERENCE_TO_ROOM(state, ref) {
      state.roomReferences.push(ref);
    },
    REMOVE_REFERENCE_FROM_ROOM(state, index) {
      state.roomReferences.splice(index, 1);
    },
    SET_JOB(state, job) {
      state.jobs.push(job);
    },
    REMOVE_JOB(state, id) {
      let index = state.jobs.map(job => job.id).indexOf(id);
      state.jobs.splice(index, 1);
    },
    SET_EXTRA(state, extra) {
      console.log("extra in mutation", extra);
      state.extraFiles.push(extra);
    },
    REMOVE_EXTRA(state, id) {
      let index = state.extraFiles.map(extra => extra.id).indexOf(id);
      state.extraFiles.splice(index, 1);
    },
    SET_EXTRA_FILE_COMMENT(state, comment) {
      state.extraFilesComment = comment;
    },
    SAVE_CLIENT_INFO(state, info) {
      state.clientInfo = info;
    },
    SET_STEP(state, step) {
      let stateHasIt = state.steps.some(step => {
        return step.name !== "passo_" + step.meta;
      });
      let newStep = {
        name: "passo_" + step.meta,
        condition: step.condition
      };
      !stateHasIt ? state.steps.push(newStep) : "";
    },
    REMOVE_STEP(state, stepIndex) {
      state.steps.splice(stepIndex, 1);
    },
    SET_ROOM_TYPE(state, type) {
      state.roomType.push(type);
    },
    REMOVE_ROOM_TYPE(state, index) {
      state.roomType.splice(index, 1);
    },
    REMOVE_OPTION(state, index) {
      state.roomOptions.splice(index, 1);
    },
    SET_OPTION(state, option) {
      state.roomOptions.push(option);
    }
  },
  actions: {
    SELECT_PLAN: (context, plan) => {
      context.commit("SELECT_PLAN", plan);
    },
    SET_REFERENCE_TO_ROOM: (context, ref) => {
      context.commit("SET_REFERENCE_TO_ROOM", ref);
    },
    REMOVE_REFERENCE_FROM_ROOM: (context, index) => {
      context.commit("REMOVE_REFERENCE_FROM_ROOM", index);
    },
    SELECT_STYLE: (context, style) => {
      context.commit("SELECT_STYLE", style);
    },
    SET_ROOM_TYPE: (context, type) => {
      context.commit("SET_ROOM_TYPE", type);
    },
    REMOVE_ROOM_TYPE: (context, index) => {
      context.commit("REMOVE_ROOM_TYPE", index);
    },
    SET_OPTION: (context, payload) => {
      context.commit("SET_OPTION", payload);
    },
    REMOVE_OPTION: (context, index) => {
      context.commit("REMOVE_OPTION", index);
    },
    UPLOAD_JOB: (context, job) => {
      context.commit("SET_JOB", job);
    },
    REMOVE_JOB: (context, id) => {
      context.commit("REMOVE_JOB", id);
    },
    UPLOAD_EXTRA: (context, extra) => {
      console.log("UPLOAD_EXTRA: (context, extra)", extra);
      context.commit("SET_EXTRA", extra);
    },
    REMOVE_EXTRA: (context, id) => {
      context.commit("REMOVE_EXTRA", id);
    },
    SET_EXTRA_FILE_COMMENT: (context, msg) => {
      context.commit("SET_EXTRA_FILE_COMMENT", msg);
    },
    SET_CLIENT_INFO: (context, payload) => {
      context.commit("SAVE_CLIENT_INFO", payload);
    },
    SET_STEP(context, step) {
      context.commit("SET_STEP", step);
    },
    REMOVE_STEP(context, step) {
      context.commit("REMOVE_STEP", step);
    },
    SEND_EMAIL: context => {
      let formData = new FormData();
      // // bluePrintImages foi subistituido por  extraFiles
      context.getters.extraFiles.forEach((img, index) => {
        // formData.append(`files[${index}]`, JSON.stringify(img.file));
        formData.append(`files[${index}]`, img.file);

        console.log("img", img);
        console.log("img index", index);
      });
      formData.append(
        "selectedPlan",
        JSON.stringify(context.getters.selectedPlan)
      );
      // formData.append("files", JSON.stringify(context.getters.extraFiles));
      // // bluePrintComment foi subistituido por  extraFiles
      formData.append(
        "extraFilesComment",
        JSON.stringify(context.getters.extraFilesComment)
      );
      // // uploadedImages foi subistituido por jobs
      formData.append("jobs", JSON.stringify(context.state.jobs));
      formData.append("roomType", JSON.stringify(context.state.roomType));
      formData.append(
        "propertyStyle",
        JSON.stringify(context.state.propertyStyle)
      );
      formData.append("roomOptions", JSON.stringify(context.state.roomOptions));
      formData.append(
        "roomReferences",
        JSON.stringify(context.state.roomReferences)
      );
      formData.append(
        "selectedImage",
        JSON.stringify(context.state.selectedImage)
      );
      formData.append("clientInfo", JSON.stringify(context.state.clientInfo));

      console.log("formData", formData);

      setTimeout(() => {
        return http()
          .post("send_email", formData)
          .then(response => {
            console.log(response.data);
          })
          .catch(error => {
            console.log(error);
          });
      }, 3000);
    }
  }
};

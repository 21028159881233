<template>
  <div class="div-principal-steps">
    <ul class="steps">
      <li
        class="nav-item"
        @click="nextPage(1)"
        :class="{ active: $route.meta.step === 1 }"
      >
        1
      </li>
      <li
        class="nav-item"
        @click="nextPage(2)"
        :class="{ active: $route.meta.step === 2 }"
      >
        2
      </li>

      <li
        class="nav-item"
        @click="nextPage(3)"
        :class="{ active: $route.meta.step === 3 }"
      >
        3
      </li>
      <li
        class="nav-item"
        @click="nextPage(4)"
        :class="{ active: $route.meta.step === 4 }"
      >
        4
      </li>
      <li
        class="nav-item"
        @click="nextPage(5)"
        :class="{ active: $route.meta.step === 5 }"
      >
        5
      </li>
      <li
        class="nav-item"
        @click="nextPage(6)"
        :class="{ active: $route.meta.step === 6 }"
      >
        6
      </li>
    </ul>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters([
      "jobs",
      "roomType",
      "clientInfo",
      "propertyStyle",
      "roomReferences",
      "selectedPlan"
    ])
  },
  methods: {
    stepOne() {
      return this.jobs.length > 0;
    },
    stepTwo() {
      return this.$masterHelper.isEmpty(this.selectedPlan);
    },
    stepThree() {
      return this.roomType.length === this.jobs.length;

      // returns true if empty otherwise false
    },
    stepFour() {
      return this.roomReferences.length === this.jobs.length;
      //
    },
    stepFive() {
      return this.$masterHelper.isEmpty(this.clientInfo);

      // returns true if empty otherwise false
    },
    stepSix() {
      // returns true if empty otherwise false
      return this.$masterHelper.isEmpty(this.clientInfo);
    },
    nextPage(val) {
      if (val === 1) {
        this.$router.push({ path: "passo_" + val });
      }
      if (val === 2) {
        if (!this.stepOne()) {
        } else {
          this.$router.push({ path: "passo_" + val });
        }
      }
      if (val === 3) {
        if (this.stepOne() && !this.stepTwo()) {
          this.$router.push({ path: "passo_" + val });
        } else {
        }
      }
      if (val === 4) {
        if (this.stepOne() && !this.stepTwo() && this.stepThree()) {
          this.$router.push({ path: "passo_" + val });
        }
      }
      if (val === 5) {
        if (
          this.stepOne() &&
          !this.stepTwo() &&
          this.stepThree() &&
          this.stepFour()
        ) {
          this.$router.push({ path: "passo_" + val });
        }
      }
      if (val === 6) {
        if (
          this.stepOne() &&
          !this.stepTwo() &&
          this.stepThree() &&
          this.stepFour() &&
          !this.stepFive()
        ) {
          this.$router.push({ path: "passo_" + val });
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.div-principal-steps {
  position: relative;
}
.steps {
  width: 30px;
  position: absolute;
  top: 35vh;
  left: 100px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 2;
}

.steps div {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #e6e8e8;
  margin: 24px 0;
  position: relative;
}

.numeric-nav-container {
  width: 30px;
  position: fixed;
  top: 50%;
  left: 100px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 2;
}

.nav-item {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-bottom: 1em;
  &:hover {
    border: 1px solid #f3a405;
  }
  &.active {
    background: #f3a405;
    color: #ffffff;
  }
}
</style>

<template>
  <div class="mb-4">
    <div class="f-card card">
      <div class="card-body f-card-body">
        <div class="total-cost">
          <div>Total</div>
          <div class="total pl-2 text-orange bolder-text">
            {{ totalCost }}
          </div>
        </div>
        <hr class="divisor-line" />

        <div class="price-option-container container pt-3">
          <div class="price-option ">
            <div class="option-text">
              Plano :
              {{ this.$masterHelper.firstCharCapital(selectedPlan.name) }}
            </div>
            <div class="option-price bold-text">
              R$ {{ selectedPlan.price }}
            </div>
          </div>
          <!-- <div class="price-option ">
            <div class="option-text">
              Espaço grande / Salas combinadas
            </div>
            <div class="option-price bold-text">
              +55,00
            </div>
          </div> -->
          <!-- <div class="price-option ">
            <div class="option-text">
              Substituição de móveis / pisos / parede
            </div>
            <div class="option-price bold-text">
              +88,00
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "PriceCard",
  mounted() {
    this.$priceHelper();
  },
  computed: {
    ...mapGetters(["totalCost", "selectedPlan"])
  }
};
</script>

<style lang="scss" scoped>
.f-card.card {
  min-height: auto;
}
.price-option {
  height: 195px;
}
</style>

<template>
  <div class="card mb-4 shadow-sm" :id="'job-room-type-' + data.id">
    <img style="height:25vh; width:100%" :src="data.src" />

    <div class="card-body">
      <div class="f-card-options ">
        <!-- //comentado por enquanto -->
        <!-- <div class="form-check">
          <span v-for="(option, index) in options" :key="option.id">
            <input
              class="form-check-input"
              type="checkbox"
              :id="option.title + data.id"
              :value="option.id"
              :checked="isChecked(option.id, data.id)"
              @click="setOptionToRoomType(option.id, index)"
            />
            <label
              class="form-check-label flex-wrap "
              :for="option.title + data.id"
            >
              {{ option.title }}
            </label>
            <br />
          </span>
        </div> -->
        <div class="w-100 mt-3">
          <select
            class="form-control"
            v-model="RoomType.roomTypeId"
            @change="setRoomType()"
          >
            <option value="#" disabled selected>Selecione o tipo</option>
            <option
              v-for="(type, roomIndex) in this.$store.getters.roomTypes"
              :key="roomIndex"
              :value="type.id"
              >{{ type.name }}</option
            >
          </select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "JobCardRoomType",
  props: {
    data: {
      type: Object,
      required: true
    },
    col: {
      type: String
    }
  },
  data() {
    return {
      RoomType: {
        image_id: this.$props.data.id,
        roomTypeId: null
      }
    };
  },
  computed: {
    ...mapGetters(["typeList", "roomOptions", "options", "roomType"])
  },
  created() {
    this.isSelected(this.$props.data.id);
  },
  methods: {
    ...mapActions([
      "SET_OPTION",
      "SET_ROOM_TYPE",
      "REMOVE_OPTION",
      "REMOVE_ROOM_TYPE"
    ]),
    setOptionToRoomType(id, index) {
      let price = this.$store.getters.options.find(opt => {
        return opt.id === id;
      });
      var option = {
        id: id,
        image_id: this.$props.data.id,
        cost: price.cost
      };
      if (this.roomOptions.length === 0) {
        this.SET_OPTION(option);
      } else {
        //filtre se a opção ja foi selecionada para a imagem
        let optIndex = this.roomOptions.findIndex(option => {
          return option.id === id && option.image_id === this.$props.data.id;
        });
        if (optIndex > -1) {
          //remova pelo index
          this.REMOVE_OPTION(optIndex);
        } else {
          this.SET_OPTION(option);
        }
      }
      this.$priceHelper();
    },
    setRoomType() {
      if (this.roomType.length === 0) {
        this.SET_ROOM_TYPE(this.RoomType);
      } else {
        let roomTypeIndex = this.roomType.findIndex(type => {
          return type.image_id === this.$props.data.id;
        });
        if (roomTypeIndex > -1) {
          this.REMOVE_ROOM_TYPE(roomTypeIndex);
          this.SET_ROOM_TYPE(this.RoomType);
        } else {
          this.SET_ROOM_TYPE(this.RoomType);
        }
      }
      this.$priceHelper();
      this.$eventHub.$emit("step_2_to_3");
    },
    isChecked(id, imgId) {
      return this.roomOptions.some(op => {
        return op.id === id && op.image_id === imgId;
      });
    },
    isSelected(id) {
      let a = this.roomType.find(typ => {
        return typ.image_id === id;
      });
      console.log("a", a);
      if (this.roomType.length > 0 && a !== undefined) {
        let x = this.roomType.find(type => {
          return type.image_id === id;
        });
        this.RoomType.roomTypeId = x.roomTypeId;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.card-body {
  label {
    font-size: 14px;
    // width: 80%;
    text-align: left;
  }
  .f-card-options {
    display: flex;
    flex-direction: column;
    align-items: self-start;
  }
}
</style>

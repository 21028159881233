<template>
  <div style="padding-top:5em; padding-left:2em;">
    <span v-if="jobs.length < 1">{{ step1() }}</span>
    <div class="row">
      <div class="col">
        <div class="div-title-step1">
          <PageHeader
            tag="h1"
            title="Para começar, envie seus arquivos"
            title_color="text-orange"
            sub_title="Certifique-se de enviar fotos de alta qualidade (> 1 MB / 3600x2400px)"
          />

          <div class="row" v-if="jobs.length < 1">
            <div class="col-md-12">
              <UploadCard />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" style="padding-top:5em;">
      <div
        class="col-12 pointer col-md-4 col-cards-planos d-flex flex-column justify-content-center "
      >
        <div
          class="box-group"
          :class="[{ selectedPlan: isSelected('basic') }]"
          @click="selectPlan({ name: 'basic', price: 349 })"
        >
          <img
            :src="require('../../assets/images/imagens_referencia/01.jpg')"
            alt="Explicação"
            srcset=""
            class="img-box"
          />
          <div class="box-descricao">
            <div class="veto-sans-bold">BASIC</div>
            <span class="subtitle-card-prices veto-sans-bold"
              >4 dias úteis</span
            >
            <br />
            <span class="subtitle-card-prices veto-sans-bold">1 Revisão</span>
            <p class="box-complemento veto-sans-bold">
              Mudança de de piso / paredes Ambientes Combinados
            </p>
            <p>
              <span class="veto-sans-bold">R$ </span>
              <strong class="veto-sans-bold">349</strong>
            </p>
            <hr class="box-hr" />
          </div>
        </div>
      </div>
      <div
        class="col-12 pointer col-md-4 col-cards-planos d-flex flex-column justify-content-center"
      >
        <div
          class="box-group"
          :class="[{ selectedPlan: isSelected('pro') }]"
          @click="selectPlan({ name: 'pro', price: 549 })"
        >
          <img
            :src="require('../../assets/images/imagens_referencia/02.jpg')"
            alt="Explicação"
            srcset=""
            class="img-box"
          />
          <div class="box-descricao">
            <div class="veto-sans-bold">PRO</div>
            <span class="subtitle-card-prices veto-sans-bold"
              >2 dias úteis</span
            >
            <br />
            <span class="subtitle-card-prices veto-sans-bold">2 Revisões</span>
            <br />
            <span class="subtitle-card-prices veto-sans-bold"
              >Mudança de piso / paredes</span
            >
            <p class="box-complemento veto-sans-bold">
              Ambientes Combinados
            </p>
            <p>
              <span class="veto-sans-bold">R$ </span>
              <strong class="veto-sans-bold">549</strong>
            </p>
            <hr class="box-hr" />
          </div>
        </div>
      </div>
      <div
        class="col-12 pointer col-md-4 col-cards-planos d-flex flex-column justify-content-center"
      >
        <div
          class="box-group"
          :class="[{ selectedPlan: isSelected('premium') }]"
          @click="selectPlan({ name: 'premium', price: 849 })"
        >
          <img
            :src="require('../../assets/images/imagens_referencia/03.jpg')"
            alt="Explicação"
            srcset=""
            class="img-box"
          />
          <div class="box-descricao">
            <div class="veto-sans-bold">PREMIUM</div>
            <span class="subtitle-card-prices veto-sans-bold">1 dia</span>
            <br />
            <span class="subtitle-card-prices veto-sans-bold">3 Revisão</span>
            <br />
            <span class="subtitle-card-prices veto-sans-bold"
              >Mudança de de piso / paredes
            </span>
            <br />
            <span class="subtitle-card-prices veto-sans-bold"
              >Ambientes Combinados</span
            >
            <p class="box-complemento"></p>
            <p>
              <span class="veto-sans-bold">R$ </span>
              <strong class="veto-sans-bold">849</strong>
            </p>
            <hr class="box-hr" />
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4 pb-5">
      <div class="col-12 d-flex justify-content-between">
        <button
          class="btn btn-primary "
          @click="$router.push({ path: 'passo_1' })"
        >
          Voltar
        </button>
        <button
          class="btn btn-primary"
          @click="$router.push({ path: 'passo_3' })"
        >
          Proximo
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import PageHeader from "@/components/PageHeader";

export default {
  components: {
    PageHeader
  },
  computed: {
    ...mapGetters(["selectedPlan", "jobs"])
  },
  methods: {
    ...mapActions(["SELECT_PLAN"]),
    step1() {
      this.$router.push({ path: "passo_1" });
    },
    isSelected(name) {
      return this.selectedPlan.name === name;
    },
    selectPlan(plan) {
      this.SELECT_PLAN(plan);
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.meta.step < 2) {
      next();
    }
    if (!this.$masterHelper.isEmpty(this.selectedPlan)) {
      next();
    } else {
      this.$notify.warning({
        position: "top right",
        title: "Espere",
        msg: "Parece que você esqueceu de selecionar um plano.",
        timeout: 6000
      });
      // next(false);
    }
  }
};
</script>

<style scoped>
.selectedPlan {
  border: 2px solid #e07a05;
  box-shadow: 0px 0px 12px -3px #e07a05;
}
.pointer {
  cursor: pointer !important;
}
.img-box {
  width: 100%;
}

.box-descricao {
  position: relative;
  background-color: #292828;
  color: white;
  padding: 16px 48px 10px 48px;
  font-weight: bold;
  font-size: 18px;
}

.box-descricao div {
  position: absolute;
  background-color: #f2b130;
  padding: 0.5vh 11vh 0.5vh 1.5vh;
  font-weight: bold;
  text-align: left;
  font-size: 18px;
  right: 0px;
  top: -2.5vh;
}

.box-descricao p.box-complemento {
  color: #807e7b;
}

.box-descricao p span {
  font-size: 12px;
  top: -1.5vh;
  position: relative;
}
.box-descricao p strong {
  font-size: 24px;
}

.box-hr {
  border: 2px solid #f2b130;
}
.box-link {
  background-color: #0c702a;
  padding: 1vh 4vh 1vh 4vh;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  margin: 0 auto;
}
.box-link a {
  color: white;
}
</style>

<template>
  <div class="div-principal-mob" style="padding-top:5em;">
    <PageHeader
      tag="h1"
      title="Que ambiente estamos projetando"
      title_color="text-orange"
      sub_title="Selecione o tipo de cada sala."
    />
    <!-- span debaixo não vai ser exibido, o mesmo só serve para executar a função -->
    <span v-if="jobs.length < 1">{{ step1() }}</span>
    <div class="row">
      <div class="col-4 cols-step2">
        <PriceCard />
      </div>
      <div class="col-8 cols-step2">
        <div class="scrolling-wrapper">
          <div
            class="col-md-6 cols-imgs-step-2"
            v-for="(job, index) in jobs"
            :key="'job-' + index"
            :class="[
              index < 1 ? 'pl-0' : '',
              jobs.length - 1 === index ? 'pr-0' : ''
            ]"
          >
            <JobCardRoomType :data="job" />
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4 pb-5">
      <div class="col-12 d-flex justify-content-between">
        <button
          class="btn btn-primary "
          @click="$router.push({ path: 'passo_2' })"
        >
          Voltar
        </button>
        <button
          class="btn btn-primary"
          @click="$router.push({ path: 'passo_4' })"
        >
          Proximo
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PageHeader from "@/components/PageHeader";
import PriceCard from "@/components/cards/PriceCard";
import JobCardRoomType from "@/components/cards/JobCardRoomType";
export default {
  components: {
    PriceCard,
    JobCardRoomType,
    PageHeader
  },
  computed: {
    ...mapGetters(["jobs", "roomType"])
  },
  methods: {
    step1() {
      this.$router.push({ path: "passo_1" });
    },
    jobsHasType() {
      return this.roomType.length === this.jobs.length;
    },
    displayErrorOnCards() {
      let jobIds = this.jobs.map(job => job.id);
      let roomTypeJobIds = this.roomType.map(roomType => roomType.image_id);
      let unSelectedJobId = this.$masterHelper.compare_arrays(
        jobIds,
        roomTypeJobIds
      );
      roomTypeJobIds.forEach(id => {
        document.getElementById("job-room-type-" + id).style.border = "none";
      });
      unSelectedJobId.forEach(unselected => {
        document.getElementById("job-room-type-" + unselected).style.border =
          "1px solid red";
      });
      unSelectedJobId.length > 0
        ? document
            .getElementById("job-room-type-" + unSelectedJobId[0])
            .scrollIntoView({ behavior: "smooth" })
        : "";
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.meta.nav) {
      next();
    } else if (to.meta.nav === false) {
      if (to.meta.step < 3) {
        next();
      } else {
        if (this.jobsHasType()) {
          next();
        } else {
          this.displayErrorOnCards();
          this.$notify.warning({
            position: "top right",
            title: "Espere",
            msg:
              "Parece que você esqueceu de selecionar o tipo de comodo em alguma imagem",
            timeout: 6000
          });
          // next(false);
        }
      }
    }
  }
};
</script>

<style lang="scss">
.total-cost {
  display: flex;
  justify-content: center;
  align-items: center;
  .total {
    font-size: 30px;
    font-weight: 700;
  }
}
.divisor-line {
  border-top: 2px solid #707070;
  width: 90%;
}
.price-option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 2em;
  .option-text {
    width: 60%;
    text-align: left;
    font-size: 14px;
  }
  .option-price {
    font-size: 20px;
    font-weight: 700;
  }
}
.f-card-body {
  margin-top: 2em;
}
.scrolling-wrapper {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  &::-webkit-scrollbar {
    height: 10px !important; /* width of the entire scrollbar */
  }
  &::-webkit-scrollbar-track {
    background: rgb(223, 223, 223); /* color of the tracking area */
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgb(136, 136, 136); /* color of the scroll thumb */
    border-radius: 10px; /* roundness of the scroll thumb */
    border: 2px solid rgb(136, 136, 136); /* creates padding around scroll thumb */
  }
}
</style>

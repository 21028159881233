<template>
  <div>
    <input
      ref="upload_extra_input"
      type="file"
      hidden
      multiple
      enctype="multipart/form-data"
      @change="handler()"
    />
    <div class="card" v-if="extraFiles.length < 1">
      <div class="card-body">
        <p>
          Carregue a planta baixa e qualquer material extra que você achar útil.
        </p>
        <button class="btn btn-outline-primary" @click="input()">
          Adicionar arquivos
        </button>
      </div>
    </div>

    <ul class="list-group" v-if="extraFiles.length > 0">
      <li
        class="list-group-item d-flex justify-content-between align-items-center"
        ref="upload_extra_card"
      >
        <span>Adicione arquivos como planta baixa</span>
        <button class="btn btn-outline-primary" @click="input()">
          Adicionar arquivos
        </button>
      </li>
      <span id="extra-file-container" class="custom-scroll">
        <li
          class="list-group-item e-info-container"
          v-for="(extra, index) in extraFiles"
          :key="'extra' + index"
        >
          <div class="e-info">
            <div class="icon">
              <box-icon name="photo-album"></box-icon>
            </div>
            <span class="ml-1 name">{{ extra.name.substr(0, 30) }}</span>
          </div>
          <span @click="removeFile(extra.id)"
            ><box-icon name="x"></box-icon
          ></span>
        </li>
      </span>
    </ul>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "ExtraFiles",
  data() {
    return {
      files: []
    };
  },
  computed: {
    ...mapGetters(["extraFiles", "extraFilesComment"])
  },
  mounted() {
    // this.$eventHub.$on("remove", index => {
    //   this.files.splice(1, index);
    //   console.log("works", index);
    // });
  },
  methods: {
    ...mapActions(["UPLOAD_EXTRA", "REMOVE_EXTRA", "SET_EXTRA_FILE_COMMENT"]),

    input() {
      this.$refs.upload_extra_input.click();
    },
    handler() {
      this.files = [];
      let uploadedFiles = this.$refs.upload_extra_input.files;
      for (var i = 0; i < uploadedFiles.length; i++) {
        this.files.push(uploadedFiles[i]);
      }
      this.getImagePreviews();
    },
    getImagePreviews() {
      if (this.files.length <= 10) {
        for (let i = 0; i < this.files.length; i++) {
          if (this.$fileController().listForExtraFile(this.files[i].name)) {
            let reader = new FileReader();
            reader.addEventListener(
              "load",
              function() {
                if (reader.result !== undefined) {
                  let extra = {
                    id: this.$IdController().generate(this.extraFiles),
                    src: reader.result,
                    file: this.files[i],
                    name: this.files[i].name,
                    size: this.formatBytes(this.files[i].size)
                  };

                  this.UPLOAD_EXTRA(extra);
                }
              }.bind(this),
              false
            );
            reader.readAsDataURL(this.files[i]);
            // console.log(this.files[i]);
          } else {
            alert(
              "Os arquivos extras tem que ser nos seguintes formatos: ai, psd, cad, pdf, pptx, doc, docx , jpg, jpeg, png, tiff."
            );
            this.files = [];
          }
        }
      } else {
        alert("Somente 10 arquivos por vez!");
        this.files = [];
      }
      const input = this.$refs.upload_extra_input;
      input.type = "text";
      input.type = "file";
    },
    removeFile(id) {
      this.REMOVE_EXTRA(id);
    },
    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return "0 Bytes";

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    }
  }
};
</script>

<style lang="scss" scoped>
.e-info-container {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between;
  align-items: center;
  .e-info {
    display: flex;
    flex-direction: row;
  }
}
#extra-file-container {
  height: 110px;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>

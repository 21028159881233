<template>
  <div class="poping-images-contianer">
    <transition-group
      name="fade"
      tag="div"
      class="d-flex align-items-baseline great-parent"
    >
      <div class="poping-item" v-for="(image, index) in images" :key="image.id">
        <div class="poping-icon-container">
          <div class="poping-icon-header">
            <box-icon
              class="poping-icon-expand"
              size="20px"
              name="fullscreen"
            ></box-icon>
          </div>
        </div>
        <img :src="require('../../assets/' + image.src)" />
      </div>
    </transition-group>
    <div class="poping-nav">
      <a class="nav-link">anterior</a>
      <a class="nav-link">próximo</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "PortfolioSlider",
  components: {},
  data() {
    return {
      images: [
        { id: "1", src: "images/sala_comercial.png" },
        { id: "2", src: "images/sofa.png" },
        { id: "3", src: "images/sala_vidro.png" }
        // { id: "4", src: "images/banheiro-xpto.jpg" },
        // { id: "5", src: "images/cozinha-xpto.webp" },
        // { id: "6", src: "images/cozinha-xpto2.jpg" },
        // { id: "7", src: "images/quarto-xpto.jpg" },
        // { id: "8", src: "images/sala-xpto.jpg" },
        // { id: "9", src: "images/sala-xpto2.jpg" },
        // { id: "10", src: "images/sala-xpto3.jpg" }
      ],
      timer: null,
      currentIndex: 0
    };
  },
  mounted() {
    // this.startSlide();
    console.log("currentImg()", this.currentImg);
  },

  methods: {
    startSlide: function() {
      this.timer = setInterval(this.next, 4000);
    },

    next: function() {
      this.currentIndex += 1;
    },
    prev: function() {
      this.currentIndex -= 1;
    }
  },

  computed: {
    currentImg() {
      return this.images;
      // return this.images[Math.abs(this.currentIndex) % this.images.length];
    }
  }
};
</script>

<style lang="scss" scoped>
.poping-images-contianer {
  display: flex;
  flex-direction: column;
  align-items: center;
  .poping-nav {
    padding-top: 1em;
    width: 100%;
    display: flex;
    justify-content: space-between;
    a {
      cursor: pointer;
      font-weight: 600;
      letter-spacing: 0.8px;
      color: #F3A405;
      text-transform: uppercase;
      &:hover {
        border-bottom: 1px solid #F3A405;
      }
    }
  }
}

.poping-item {
  position: relative;
  margin-right: 1.5em;
  img {
    width: 459px;
    height: 308px;
  }
  &:nth-child(2) {
    img {
      width: 471px !important;
      height: 330px !important;
    }
  }
}

.poping-item-bigger {
  width: 471px !important;
  height: 330px !important;
}
.poping-icon-container {
  // display: flex;
  width: 100% !important;
  position: relative;
}
.poping-icon-header {
  width: 100% !important;

  position: absolute;
  display: flex;
  flex-direction: row;
  height: 20px;
  justify-content: flex-end;
}
.poping-icon-expand {
  padding-right: 10px;
  padding-top: 5px;
  fill: #F3A405;
}
</style>

<template>
  <div class="header-component ">
    <span :class="title_color">
      <h1 v-if="tag === 'h1'" :class="tipo_titulo" v-html="title"></h1>
      <h2 v-if="tag === 'h2'" :class="tipo_titulo" v-html="title"></h2>
      <h3 v-if="tag === 'h3'" :class="tipo_titulo" v-html="title"></h3>
      <h4 v-if="tag === 'h4'" :class="tipo_titulo" v-html="title"></h4>
      <h5 v-if="tag === 'h5'" :class="tipo_titulo" v-html="title"></h5>

    </span>

    <div class="sub-title" v-if="sub_title!==''">
      <p :class="subTitle_color">
        {{ sub_title }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageHeader",
  props: {
    title: {
      required: true
    },
    sub_title: {
      required: false
    },
    tag: {
      required: true
    },
    title_color: {
      required: false
    },
    subTitle_color: {
      required: false
    },
    tipo_titulo: {
      require: false
    }
  }
};
</script>

<style lang="scss">
.header-component {
  display: flex;
  flex-direction: column;
  text-align: left;

  .sub-title {
    margin-top: 1em;
  }
}
</style>

<template>
  <div
    class="row div-principal-mob justify-content-center "
    style="padding-top:5em;"
  >
    <!-- span debaixo não vai ser exibido, o mesmo só serve para executar a função -->
    <span v-if="jobs.length < 1">{{ step1() }}</span>
    <div class="col-md-6 col-form-steps">
      <div class="step-5 card p-3">
        <div class="card-body">
          <div class="logo mb-4">
            <img
              class="img-responsive"
              :src="require('../../assets/images/logo/icon_logo.svg')"
              width="100%"
              height="80"
            />
          </div>
          <form v-on:submit.prevent="next('from-info')" id="from-info">
            <div class="form-group">
              <label for="name">Nome</label>
              <input
                v-model="user.name"
                type="text"
                class="form-control"
                id="name"
                aria-describedby="name"
              />
            </div>
            <div class="form-group">
              <label for="CPFCNPJ">CPF - CNPJ</label><br />
              <small class="text-muted">Somente numeros</small>
              <input
                v-model="user.cpf_cnpj"
                type="text"
                class="form-control"
                id="CPFCNPJ"
              />
            </div>
            <div class="form-group">
              <label for="Telefone">Telefone</label>
              <input
                v-model="user.contact"
                type="text"
                class="form-control"
                id="Telefone"
              />
            </div>
            <div class="form-group">
              <label for="Email">E-mail</label>
              <input
                v-model="user.email"
                type="email"
                class="form-control"
                id="Email"
                :class="[checkEmail() ? 'required' : '']"
              />
            </div>
            <div class="form-group">
              <label for="cEmail">Confirmar E-mail</label>
              <input
                v-model="user.emailConfirm"
                type="email"
                class="form-control"
                id="cEmail"
                @keyup="checkEmail()"
                :class="[checkEmail() ? 'required' : '']"
              />
            </div>

            <div class="form-group">
              <label for="Endereco">Endereço</label>
              <input
                v-model="user.address"
                type="text"
                class="form-control"
                id="Endereco"
              />
            </div>

            <!-- <div class="row justify-content-center mt-4">
              <button type="submit" class="btn btn-primary ">Proximo</button>
            </div> -->
            <div class="row mt-4 ">
              <div class="col-12 d-flex justify-content-between">
                <button
                  class="btn btn-primary "
                  @click.prevent="$router.push({ path: 'passo_4' })"
                >
                  Voltar
                </button>
                <button type="submit" class="btn btn-primary ">Proximo</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      user: { name: "", email: "", emailConfirm: "", contact: "", address: "" }
    };
  },
  created() {
    this.infoIsSet();
  },
  computed: {
    ...mapGetters(["clientInfo", "jobs"])
  },
  methods: {
    ...mapActions(["SET_CLIENT_INFO"]),
    step1() {
      this.$router.push({ path: "passo_1" });
    },
    validateForm(formId) {
      console.log("formId", formId);
      var form = document.querySelectorAll("#" + formId + " " + "input");
      var empty = [];
      console.log("validateForm with form:", form);

      [...form].filter(input => {
        if (input.value === "") {
          empty.push(input.id);
        }
      });

      var classExists = document.getElementsByClassName(
        "error-message-elemets"
      );

      if (empty.length > 0 && classExists.length === 0) {
        empty.forEach(inputId => {
          var warning = document.createElement("small");
          warning.classList.add("text-danger");
          warning.classList.add("error-message-elemets");
          warning.innerText = "* Campo obrigatorio";
          let x = document.getElementById(`${inputId}`);
          x.classList.add("input-error-message");
          x.after(warning);
          console.log("x is", x);
        });
        console.log("Sem submit");
        return false;
      } else {
        console.log("entrtou akiiiii");
        if (classExists.length > 0 && empty.length === 0) {
          console.log("class exists", classExists);
          console.log("empty has..", empty);
          var msgs = document.getElementsByClassName("input-error-message");
          [...msgs].forEach(msg => {
            msg.classList.remove("input-error-message");
          });
          [...classExists].forEach(e => e.remove());
          return true;
        } else if (classExists.length === 0 && empty.length === 0) {
          return true;
        }
      }
    },
    next(formId) {
      if (this.validateForm(formId)) {
        this.SET_CLIENT_INFO(this.user);
        this.$router.push({ path: "passo_6" });
      }
    },
    checkEmail() {
      if (this.user.emailConfirm.length > 3) {
        return this.user.email !== this.user.emailConfirm;
      }
    },
    infoIsSet() {
      if (!this.$masterHelper.isEmpty(this.clientInfo)) {
        this.user = this.clientInfo;
      }
    }
  }
};
</script>

<style lang="scss">
.step-5.card {
  input {
    border-radius: 11px;
    border: none;
    box-shadow: 0px 3px 6px #00000029;
    &:focus {
      border: 1px #fd9c2e solid;
    }
  }
  label {
    font-weight: 700;
  }
}
.required {
  border: 1px red solid !important;
}
</style>

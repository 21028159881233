<template>
  <div class="f-service-card">
    <div class="left-info">
      <div class="info-container">
        <h4 class="pt-3 veto-sans-bold">{{ title }}</h4>
        <p class="pt-3 veto-sans-light">
          {{ text }}
        </p>
      </div>
    </div>
    <div class="right-info">
      <img :src="src" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String
    },
    text: {
      required: true
    },
    src: {
      type: String
    }
  }
};
</script>

<style lang="scss">
.f-service-card {
  display: flex;
  background: #292828;
  width: 100%;
  -webkit-box-shadow: 0px 0px 31px -6px rgba(0, 0, 0, 0.69);
  box-shadow: 0px 0px 31px -6px rgba(0, 0, 0, 0.69);
  margin-bottom: 2em;
  .left-info {
    // background: #5f5c5c;
    // height: 100%;
    width: 40%;
    color: #ffffff;
    text-align: left;
    padding: 2em;
    display: flex;
    align-items: center;
    .info-container {
      width: 100%;
      border-top: 3px solid #F3A405;
      border-bottom: 3px solid #F3A405;
    }
  }
  .right-info {
    background: #8f6969;
    max-height: 40vh;
    width: 60%;
    img {
      height: 100%;
      width: 101%;
    }
  }
}
</style>

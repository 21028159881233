<template>
  <div>
    <div class="fundo container-fluid">
        <div class="row-sem-fundo">
            <Contact />
        </div>
      </div>
  </div>
</template>

<script>
import Contact from "@/components/navigation/Contact";
export default {
  components: {
    Contact,
  },
};
</script>


<template>
  <div class="card" :id="'job-checkout-card-' + data.id">
    <div class="card-body">
      <div class="line-info d-flex justify-content-between">
        <span>Job {{ data.id }} - Tipo {{ getRoomType(data.id) }}</span>
        <span @click.prevent="removeJobCard(data.id)"
          ><box-icon name="trash" class="cursor"></box-icon
        ></span>
      </div>
      <div class="job-row-checkout">
        <img
          width="200"
          height="150"
          class="mr-3 f-border-primary img-cli-final"
          :src="data.src"
        />
        <img width="200" height="150" :src="getImgRefSelected(data.id).src" />
        <div class="selected-colors">
          <div
            class="color-item"
            v-for="color in getImgRefSelected(data.id).colors"
            :key="color.color"
            :style="{ background: color.color }"
          ></div>
        </div>
        <div class="comment">
          <h5>Comentarios</h5>
          <div class="text">
            {{ getImgRefSelected(data.id).comment }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "CheckoutJobs",
  props: ["data"],
  data() {
    return {
      allJobs: null
    };
  },
  computed: {
    ...mapGetters([
      "roomType",
      "roomTypes",
      "roomReferences",
      "roomOptions",
      "jobs"
    ])
  },

  mounted() {
    this.allJobs = this.jobs;
  },
  methods: {
    ...mapActions([
      "REMOVE_ROOM_TYPE",
      "REMOVE_REFERENCE_FROM_ROOM",
      "REMOVE_OPTION",
      "REMOVE_JOB"
    ]),
    getRoomType() {
      var roomType = this.roomType.find(type => {
        return type.image_id === this.$props.data.id;
      });
      console.log("1...");
      console.log("1...roomType", roomType);
      if (roomType) {
        let name = this.roomTypes.find(t => {
          return t.id === roomType.roomTypeId;
        }).name;
        console.log("2...name", name);
        return name;
      }
    },
    getImgRefSelected() {
      return this.roomReferences.find(roomRef => {
        return roomRef.image_id === this.$props.data.id;
      });
    },
    removeJobCard() {
      //TODO animation on remove #bug
      //Add animations
      //Obs: issues may happen when animation is running
      //Dont use animation, maybe the solutuion is to store the data in a variable in the component insted of getting from the mapped getter
      // document
      //   .getElementById("job-checkout-card-" + this.$props.data.id)
      //   .classList.add("fade-out-leave-active");
      this.deleteJobRelationship();
      // setTimeout(() => {
      //   this.deleteJobRelationship();
      // }, 1000);
    },
    deleteJobRelationship() {
      var id = this.$props.data.id;

      console.log("1...");
      let typeIndex = this.roomType.findIndex(type => {
        return type.image_id === id;
      });
      console.log("1...typeIndex", typeIndex);
      let refIndex = this.roomReferences.findIndex(ref => {
        return ref.image_id === id;
      });
      console.log("1...refIndex", refIndex);

      let roomOptIndex = this.roomOptions.findIndex(opt => {
        return opt.image_id === id;
      });
      console.log("1...roomOptIndex", roomOptIndex);

      let jobIndex = this.jobs.findIndex(job => {
        return job.id === id;
      });
      console.log("1...jobIndex", jobIndex);

      this.REMOVE_OPTION(roomOptIndex);
      this.REMOVE_ROOM_TYPE(typeIndex);
      this.REMOVE_REFERENCE_FROM_ROOM(refIndex);
      this.REMOVE_JOB(id);
      this.$priceHelper();
      this.allJobs = this.jobs;
    }
  }
};
</script>

<style lang="scss">
.job-row-checkout {
  display: flex;
  // align-items: center;
  // justify-content: space-between;
  .comment {
    width: 80%;
    display: flex;
    flex-direction: column;
    .text {
      flex-wrap: wrap;
    }
  }
}
.selected-colors {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1em;
  .color-item {
    border: 1px #e2e2e2 solid;
    border-radius: 25px;
    display: flex;
    width: 25px;
    height: 25px;
    margin-bottom: 0.3em;
    justify-content: center;
    align-items: center;
    .check-icon {
      font-size: 24px;
      fill: #fff;
      stroke: #030303;
      stroke-width: 1;
      stroke-linecap: round;
      stroke-linejoin: round;
    }
  }
}
</style>

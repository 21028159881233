<template>
  <div id="body-container">
    <div id="overlay"></div>
    <!-- Navigation -->
    <!-- <NavBarTop /> -->

    <!-- Page Content -->
    <div class="fundo container-fluid">
      <NumNav />
      <div>
        <div class="container">
          <transition name="slide-in" mode="out-in">
            <router-view />
          </transition>
        </div>
      </div>
      <portal-target name="modals" />
    </div>
    <Footer />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import NumNav from "@/components/navigation/NumberNavigation";
import Footer from "@/components/navigation/Footer";

export default {
  components: {
    Footer,
    NumNav
  },
  computed: {
    ...mapGetters(["jobs", "extraFilesComment"])
  }
};
</script>

<style lang="scss">
#margins {
  padding-top: 80px;
}
#body-container {
  height: 100vh !important;
}

.vue-notification {
  border: unset !important;
  .vue-notification-title {
    font-size: 16px !important;
    letter-spacing: 1px;
    border-bottom: none !important;
  }
  .vue-notification-msg {
    font-size: 16px !important;
    color: #000000 !important;
  }
  &.warning {
    background: #ffda08 !important;
    .vue-notification-title {
      color: #000000 !important;
    }
    .vue-notification-msg {
      color: #000000 !important;
    }
  }
}
</style>

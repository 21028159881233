import axios from "axios";

export default () => {
  // const USER_TOKEN = localStorage.getItem("access_token");
  // const AuthStr = "Bearer ".concat(USER_TOKEN);
  return axios.create({
    baseURL: "https://app.fullfreela.com/api/",
    // baseURL: "http://fullfreela.local/api/",

    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "multipart/form-data"
    }
    // timeout: 5000
  });
};

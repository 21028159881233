<template>
  <div>
    <div class="container py-5">
      <div class="row p-3">
        <div class="reposta-email">
          <span class="sucesso">{{ resposta_email }}</span>
        </div>
        <form class="form-contato w-100" @submit.prevent="sendEmail">
          <h1 class="text-white veto-sans-medium">CONTATO</h1>
          <h5 class="mb-4 text-white veto-sans-light">
            *Preencha os dados abaixo e entraremos em contato.
          </h5>
          <div
            class="d-flex mb-3 veto-sans-light-all"
            id="div-inputs-name-email"
          >
            <div class="mr-2 w-100" id="div-input-name">
              <input
                class="w-100 alter-input"
                placeholder="Nome*"
                required
                type="text"
                v-model="Email.from_name"
                name="from_name"
              />
            </div>
            <div class="w-100" id="div-input-email">
              <input
                class="w-100 alter-input"
                placeholder="Email*"
                required
                type="email"
                v-model="Email.user_email"
                name="user_email"
              />
            </div>
          </div>
          <div class="d-flex veto-sans-light-all">
            <div class="w-100">
              <textarea
                class="w-100 alter-input pt-3"
                placeholder="Menssagem"
                cols="30"
                rows="7"
                v-model="Email.message"
                name="message"
              ></textarea>
            </div>
          </div>
          <div class="w-100 d-flex">
            <input
              type="submit"
              value="Send"
              class="ml-auto btn btn-contato py-3 px-5 veto-sans-bold"
            />
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
// import http from "../../http";
import emailjs from "emailjs-com";
export default {
  name: "ContactUs",
  data() {
    return {
      resposta_email: "",

      Email: {
        from_name: "",
        user_email: "",
        message: "",
        to_name: "leo"
      }
    };
  },
  methods: {
    sendEmail(e) {
      emailjs
        .sendForm(
          "service_ojswpqi",
          "template_gyvpqt7",
          e.target,
          "user_zfz2i4md7JOMssYHKqtTN",
          this.Email
        )
        .then(
          result => {
            console.log("SUCCESS!", result.status, result.text);
            this.from_name = "";
            this.user_email = "";
            this.message = "";
            this.to_name = "leo";
            this.resposta_email = "E-mail enviado com sucesso!!!";
          },
          error => {
            console.log("FAILED...", error);
          }
        );
    }
  }

  // methods:{
  //   // enviarEmail(){
  //   //   http()
  //   //     .post("lerAbaixo", this.formContato)
  //   //     .then(response => {
  //   //       console.log(response.data);
  //   //       this.formContato = {};
  //   //     })
  //   //     .catch(error => {
  //   //       console.log(error);
  //   //     });
  //   // }
  //   // acima substituir o lerAbaixo pela rota do envio do email no backend
  // }
};
</script>
<style scoped></style>

<template>
  <div class="div-principal-mob" style="padding-top:5em;">
    <!-- span debaixo não vai ser exibido, o mesmo só serve para executar a função -->
    <span v-if="jobs.length < 1">{{ step1() }}</span>
    <div class="row  mb-3">
      <div class="col-md-12">
        <div class="card">
          <div
            class="card-body card-info-cliente d-flex justify-content-between"
          >
            <span class="infos-cliente">
              <div class="line-info">
                <span
                  ><span class="bold-text mr-2">Nome:</span
                  >{{ clientInfo.name }}</span
                >
              </div>
              <div class="line-info">
                <span
                  ><span class="bold-text mr-2">CPF/CNPJ:</span
                  >{{ clientInfo.cpf_cnpj }}</span
                >
              </div>
              <div class="line-info">
                <span
                  ><span class="bold-text mr-2">Telefone:</span
                  >{{ clientInfo.contact }}</span
                >
              </div>
              <div class="line-info">
                <span
                  ><span class="bold-text mr-2">Endereço:</span
                  >{{ clientInfo.address }}</span
                >
              </div>
              <div class="line-info">
                <span
                  ><span class="bold-text mr-2">Plano:</span>
                  {{ this.$masterHelper.firstCharCapital(selectedPlan.name) }} -
                  R$ {{ selectedPlan.price }}</span
                >
              </div>
              <div class="line-info">
                <span
                  ><span class="bold-text mr-2">Total:</span>
                  {{ totalCost }}</span
                >
              </div>
            </span>
            <span class="img-info-cliente">
              <img width="200" :src="propertyStyle.src" alt="" />
              <div class="line-info">
                <span
                  ><span class="bold-text mr-2">Estilo:</span
                  >{{ getStyleName() }}</span
                >
              </div>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-3 checkout-container">
      <div class="col-md-12 mb-4 " v-for="(job, index) in jobs" :key="index">
        <CheckoutJobs :data="job" />
      </div>
    </div>
    <div class="row mt-4 pb-5">
      <div class="col-12 d-flex justify-content-between">
        <button
          class="btn btn-primary "
          @click="$router.push({ path: 'passo_5' })"
        >
          Voltar
        </button>
        <button class="btn btn-primary" @click="SEND_EMAIL()">
          Concluir
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CheckoutJobs from "@/components/CheckoutJobs";
export default {
  components: {
    CheckoutJobs
  },
  computed: {
    ...mapGetters([
      "totalCost",
      "jobs",
      "clientInfo",
      "propertyStyle",
      "roomDesignCategories",
      "selectedPlan"
    ])
  },
  // watch: {
  //   jobs: {
  //     deep: true,
  //     // immediate: true,
  //     handler: function(newValue) {
  //       console.log("newValue-----", newValue);
  //       if (newValue.length === 0) {
  //         this.$router.push({ path: "passo_1" });
  //       }
  //     }
  //   }
  // },
  methods: {
    ...mapActions(["SEND_EMAIL"]),
    getStyleName() {
      if (!this.propertyStyle.roomDesignCategoryID) {
        return "Não foi escolhido";
      }
      return this.roomDesignCategories.find(category => {
        return category.id === this.propertyStyle.roomDesignCategoryID;
      }).category;
    },
    step1() {
      this.$router.push({ path: "passo_1" });
    }
  }
};
</script>

<style lang="scss">
.line-info {
  &:not(:last-child) {
    margin-bottom: 1em;
  }
}
.selected-colors {
  width: 7%;
  display: flex;
  flex-direction: column;
  .color {
    height: 25px !important;
    width: 25px !important;
    // background: red !important;
    margin-bottom: 1em;
  }
}

.checkout-container {
  padding: 0.5em;
  overflow-y: scroll;
  overflow-x: hidden;

  height: 600px;
  &::-webkit-scrollbar {
    width: 10px !important; /* width of the entire scrollbar */
  }
  &::-webkit-scrollbar-track {
    background: rgb(223, 223, 223); /* color of the tracking area */
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgb(136, 136, 136); /* color of the scroll thumb */
    border-radius: 10px; /* roundness of the scroll thumb */
    border: 2px solid rgb(136, 136, 136); /* creates padding around scroll thumb */
  }
}
</style>

<template>
  <div class="container-fluid fundo">
    <div class="container row-sem-fundo">
      <div class="row d-flex justify-content-center mt-3 ">
        <div
          class="col-lg-10 col-md-10 d-flex align-items-center justify-content-center"
        >
          <p class="lead veto-sans-bold font-size-50 text-orange title-mob">
            Escala para seus projetos 3D
          </p>
        </div>
        <div
          class="col-lg-10 col-md-10 mx-auto mt-3 mb-5 text-center div-subtitle-home"
        >
          <p class="lead veto-sans-medium-all font-size-20" id="subtitle-home">
            Na <span class="logo-orange ">FullFreela</span> conseguimos trazer
            escala para seus projetos. Desde modelagem de móveis a cenas
            complexas. Você pode usar em sua e-commerce, catálogos ou mídias
            sociais.
          </p>
        </div>
      </div>
      <div class="row w-100 row-col-imgs-home">
        <div
          class="col-12 col-md-3 col-imgs-home"
          v-for="img in images"
          :key="img.id"
        >
          <img
            class="img-responsive"
            :src="require('../../assets/' + img.src)"
            alt=""
            width="100%"
          />
        </div>
      </div>
      <div class="row mt-5 mb-4">
        <div
          class="col-lg-12 col-md-12 pt-2 mx-auto  text-center d-flex align-items-center flex-column"
        >
          <h3 class="lead font-size-30 veto-sans-bold title-mob">
            O que fazemos?
          </h3>
        </div>
      </div>
      <div class="row d-flex justify-content-center mb-5" id="row-solucoes">
        <div class="col-lg-10 col-md-10 d-flex align-items-center ">
          <div
            class="col-12 col-md-12 fundo-hover-galeria div-galeria-mob"
            :style="{
              backgroundImage:
                'url(' + require('../../assets/images/piscina.webp') + ')',
              backgroundSize: 'cover'
            }"
          >
            <div class="align-items-center">
              <router-link id="link-title-galeria" :to="{ name: 'ecommerce' }">
                <span
                  class="texto-hover-galeria veto-sans-medium "
                  id="title-galeria"
                  >Soluções para e-commerce</span
                >
              </router-link>
            </div>
          </div>
        </div>
        <div class="box-link mt-4">
          <router-link
            class="nav-link veto-sans-bold"
            :to="{ name: 'ecommerce' }"
          >
            Saiba Mais
          </router-link>
        </div>
      </div>
      <div
        class="row d-flex justify-content-center my-5 py-5"
        id="row-decorado-home"
      >
        <div class="col-lg-10 col-md-10 d-flex justify-content-center ">
          <div class="w-100 position-relative">
            <twenty-twenty
              class="d-flex justify-content-center"
              :before="require('../../assets/images/imgs_twenty/1A.jpg')"
              :after="require('../../assets/images/imgs_twenty/1B.jpg')"
            />
            <div class="d-flex align-items-center">
              <router-link class="fundo-link" :to="{ name: 'virtual-staging' }">
                <span
                  class="texto-hover-galeria veto-sans-medium"
                  id="title-decorado-home"
                  >Decorado Virtual</span
                >
              </router-link>
            </div>
          </div>
        </div>
        <div class="box-link mt-4">
          <router-link
            class="nav-link veto-sans-bold"
            :to="{ name: 'virtual-staging' }"
          >
            Saiba Mais
          </router-link>
        </div>
      </div>
      <!-- <div class="row d-flex justify-content-center my-5 " >
            <div class="col-lg-10 col-md-10 d-flex justify-content-center text-center">
                <div class="container" >
                    <h1>Quer ser um Freelancer? </h1>
                    <img class="img-responsive" :src="require('../../assets/images/student-desk.webp')" alt="" srcset="" />
                </div>
            </div>
        </div>

        <div class="row my-5 " >
            <div class="col-lg-12 col-md-12 p-2 mx-auto  text-center d-flex align-items-center flex-column">
                <div  class="col-lg-5 col-md-5 font-size-20 text-silver">
                    <div class="container" >
                        <p>
                            É um artista 3D e quer fazer parte da nossa plataforma?
                            Faça o cadastro com seu portfólio
                        </p>
                        <div class="box-link-blue">
                            <router-link class="nav-link" :to="{ name: 'Step-1' }">
                            Cadastro Freela
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
      <Contact />
    </div>
  </div>
  <!-- // { id: "5", src: "images/lustre.png" } -->
</template>
<script>
import Contact from "@/components/navigation/Contact";
export default {
  data() {
    return {
      images: [
        { id: "1", src: "images/sala_comercial.png" },
        { id: "2", src: "images/sofa.png" },
        { id: "3", src: "images/sala_vidro.png" },
        { id: "4", src: "images/sala.png" }
      ]
    };
  },
  components: {
    Contact
  }
};
</script>

<style lang="scss">
.f-hero {
  .heading {
    // font: normal normal bold 25px/38px Trust Sans;
    letter-spacing: 0.25px;
    font-weight: 700 !important;
  }
}
.logo-orange {
  color: #f3a405 !important;
}

.logo-orange {
  color: #f2b130 !important;
}

.f-carousel {
  display: flex;
  justify-content: center;
  align-items: center;
}
.f-carousel-item {
  width: 247px;
  height: 289px;
  img {
    height: inherit;
  }
  // background: gray;
  margin-right: 1em;
}

.centralizar {
  margin: 0 auto;
}

.fundo-hover-galeria {
  height: 58vh;
  width: 100%;
}

.fundo-hover-galeria div,
.fundo-link {
  font-size: 3rem;
  color: #ffffff;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  min-height: 14vh;
  background: linear-gradient(
    90deg,
    rgba(46, 43, 43, 0.701) 0%,
    rgba(46, 43, 43, 0.701) 0%
  );

  text-align: center;
  display: flex;
  justify-content: center;
  z-index: 10000;
}
.fundo-hover-galeria:hover div,
.fundo-link:hover {
  background: linear-gradient(
    90deg,
    rgba(46, 43, 43, 0.701) 0%,
    rgba(8, 8, 8, 0.695) 100%
  );
  opacity: 1;
  color: #ffffff;
}

.fundo-hover-galeria div a,
.fundo-link a {
  color: #ffffff;
}

.fundo-hover-galeria div a:hover,
.fundo-link:hover {
  color: #ffffff;
  text-decoration: none;
}

.fundo-link span {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

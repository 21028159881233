<template>
  <div
    ref="upload_job_card"
    class="card card-upload"
    draggable="true"
    @drop="input()"
    @click="input()"
    @dragover="hover()"
    @dragleave="hover('leave')"
  >
    <div class="card-body">
      <input
        ref="upload_job_input"
        type="file"
        hidden
        multiple
        @change="handler()"
      />
      <h4>Adicione seus arquivos</h4>

      <button class="btn btn-outline-primary mt-2">
        Adicionar
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "UploadCard",
  data() {
    return {
      files: []
    };
  },
  computed: {
    ...mapGetters(["jobs"])
  },
  mounted() {},
  methods: {
    ...mapActions(["UPLOAD_JOB"]),
    hover(event = null) {
      this.$refs.upload_job_card.style.border = "1px solid red";
      if (event === "leave") {
        this.$refs.upload_job_card.style.border = "unset";
      }
    },
    input() {
      this.$refs.upload_job_input.click();
    },
    handler() {
      this.files = [];
      let uploadedFiles = this.$refs.upload_job_input.files;
      for (var i = 0; i < uploadedFiles.length; i++) {
        this.files.push(uploadedFiles[i]);
      }
      this.getImagePreviews();
    },
    getImagePreviews() {
      if (this.files.length <= 10) {
        for (let i = 0; i < this.files.length; i++) {
          if (this.$fileController().listForImage(this.files[i].name)) {
            let reader = new FileReader();
            reader.addEventListener(
              "load",
              function() {
                if (reader.result !== undefined) {
                  let img = {
                    id: this.$IdController().generate(this.jobs),
                    src: reader.result,
                    name: this.files[i].name,
                    size: this.formatBytes(this.files[i].size)
                  };

                  this.UPLOAD_JOB(img);
                  this.$eventHub.$emit("update-step");
                }
              }.bind(this),
              false
            );
            reader.readAsDataURL(this.files[i]);
            // console.log(this.files[i]);
          } else {
            alert(
              "As imagens devem estar no formato: JPG, JPEG , PNG ou WEBP "
            );
            this.files = [];
          }
        }
      } else {
        alert("Somente 10 arquivos por vez!");
        this.files = [];
      }
      const input = this.$refs.upload_job_input;
      input.type = "text";
      input.type = "file";
    },
    removeFile(key) {
      // this.files.splice(key, 1);
      // this.$NavValidation($route.name);
      this.$store.dispatch("REMOVE_IMAGE", key);

      // this.getImagePreviews();
    },
    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return "0 Bytes";

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    }
  }
};
</script>

<style></style>

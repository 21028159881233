<template>
  <div class=" text-center div-principal-mob" style="padding-top:5em;">
    <div class="div-title-step1">
      <PageHeader
        tag="h1"
        title="Para começar, envie seus arquivos"
        title_color="text-orange"
        sub_title="Certifique-se de enviar fotos de alta qualidade (> 1 MB / 3600x2400px)"
      />

      <div class="row" v-if="jobs.length < 1">
        <div class="col-md-12">
          <UploadCard />
        </div>
      </div>
    </div>

    <div class="row  jobs-container">
      <div class="col-md-4 col-upload-img" v-if="jobs.length > 0">
        <UploadCard />
      </div>
      <div
        class="col-4 col-imgs-upload-step1 mt-4"
        v-for="(job, index) in jobs"
        :key="index"
      >
        <Thumbnail :data="job" :index="index" type="job-thumb" />
      </div>
    </div>
    <div class="pt-5 div-title-step1">
      <PageHeader
        tag="h1"
        title_color="text-orange"
        title="Faça upload de arquivos adicionais"
        sub_title="Escolha a direção principal do estilo de sua propriedade."
      />
      <div class="row">
        <div class="col-md-6">
          <ExtraFiles />
        </div>
        <div class="col-md-6">
          <div class="card p-1">
            <textarea
              v-model="comment"
              @keyup="saveComment()"
              class="form-control"
              id="extra-textarea"
              rows="4"
              placeholder="Comentarios..."
            ></textarea>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-5 pb-5">
      <div class="col-12">
        <button
          class="btn btn-primary float-right"
          @click="$router.push({ path: 'passo_2' })"
        >
          Próximo
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import PageHeader from "@/components/PageHeader";
import UploadCard from "@/components/cards/UploadCard";
import ExtraFiles from "@/components/cards/ExtraFiles";
import Thumbnail from "@/components/cards/Thumbnail";

export default {
  components: {
    PageHeader,
    UploadCard,
    ExtraFiles,
    Thumbnail
  },
  data() {
    return {
      comment: null
    };
  },
  computed: {
    ...mapGetters(["jobs", "extraFilesComment"])
  },
  created() {
    console.log("created");
  },
  mounted() {
    console.log("comment", this.extraFilesComment);
    document.getElementById("extra-textarea").value =
      this.extraFilesComment.length > 0 ? this.extraFilesComment : "";
  },
  methods: {
    ...mapActions(["SET_EXTRA_FILE_COMMENT"]),
    saveComment() {
      setTimeout(() => {
        this.SET_EXTRA_FILE_COMMENT(this.comment);
        console.log("saving comments");
      }, 200);
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.meta.nav) {
      next();
    } else if (to.meta.nav === false) {
      if (this.jobs.length > 0) {
        next();
      } else {
        this.$notify.warning({
          position: "top right",
          title: "Espere",
          msg: "Você precisa fazer upload das imagens",
          timeout: 6000
        });
        // next(false);
      }
    }
  }
};
</script>

<style lang="scss">
.jobs-container {
  padding: 0.5em;
  overflow-y: scroll;
  overflow-x: hidden;

  height: auto;
  max-height: 360px;
  &::-webkit-scrollbar {
    width: 10px !important; /* width of the entire scrollbar */
  }
  &::-webkit-scrollbar-track {
    background: rgb(223, 223, 223); /* color of the tracking area */
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgb(136, 136, 136); /* color of the scroll thumb */
    border-radius: 10px; /* roundness of the scroll thumb */
    border: 2px solid rgb(136, 136, 136); /* creates padding around scroll thumb */
  }
}
</style>

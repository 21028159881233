import store from "../store/index.js";

var jobs = store.getters.jobs;
var roomOptions = store.getters.roomOptions;
var roomType = store.getters.roomType;
var totalCost = store.getters.totalCost;
var plan = store.getters.selectedPlan.price;

var optionsData = store.getters.options;

export const PriceController = () => {
  console.log("Quantas images?", jobs);
  //As imagens tem chackbox?
  console.log("As imagens tem chackbox?", roomOptions);
  //Qual o tipo de comodo de cada imagem?
  console.log("Qual o tipo de comodo de cada imagem?", roomType);
  console.log("makeCalculation", makeCalculation());
  store.dispatch("SET_COST", makeCalculation());
};

const makeCalculation = () => {
  var total = 0;
  var Jobs = jobs.length;
  var Options = roomOptions.length;
  var valorPorJob = store.getters.selectedPlan.price;
  console.log("qual plano?", valorPorJob);
  total = total + valorPorJob * Jobs;
  Options > 0
    ? roomOptions.forEach(opt => {
        total = total + opt.cost;
      })
    : "";

  return total.toFixed(2);
};

<template>
  <div>
    <div class="container ">
      <PageHeader
        class="bold-text"
        tag="h1"
        title="Portifolio"
        title_color="text-orange"
        sub_title=""
      />
    </div>
    <div class="container pt-4">
      <!-- //slider -->
      <PortfolioSlider />
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import PortfolioSlider from "@/components/page/PortfolioSlider";

export default {
  components: {
    PageHeader,
    PortfolioSlider
  }
};
</script>

<style lang="scss"></style>

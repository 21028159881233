import store from "../store/index.js";
import router from "../router/index.js";
// $router.options.routes[0].children[3].children
export const NavValidation = r => {
  // validar o passo atual
  var steps = [];
  // router.options.routes[0].children[3].children.forEach(route => {
  //   if (parseInt(route.meta.step) === 1) {
  //     console.log("(route.meta.path) === 1 in NavValidation", route.meta);
  //     steps.push({ [route.name]: true });
  //   } else if (parseInt(route.meta.step) !== 1) {
  //     console.log("(route.meta.path) !== 1 in NavValidation", route.meta);
  //     steps.push({ [route.name]: false });
  //   }
  // });
  // console.log("steps:[] in NavValidation", steps);

  //verificar se cliente concluiu o passo da pagina atual
  if (store.getters.jobs.length > 0) {
    store.dispatch.SET_STEP({ [r.name]: true });
  }
};

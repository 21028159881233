import Vue from "vue";
import Vuex from "vuex";
import references from "../../database-json/references.json";

Vue.use(Vuex);

export default {
  state: {
    // roomType: [],
    // roomOptions: [],
    // propertyStyle: {},
    typeList: [
      {
        id: 1,
        name: "Banheiro",
        cost: "100,33"
      },
      {
        id: 2,
        name: "Copa",
        cost: "80,50"
      },
      {
        id: 3,
        name: "Cozinha",
        cost: "100,33"
      },
      {
        id: 4,
        name: "Quarto",
        cost: "150,80"
      },
      {
        id: 5,
        name: "Sala",
        cost: "250,90"
      },
      {
        id: 6,
        name: "Garagem",
        cost: "500,00"
      },
      {
        id: 7,
        name: "Varanda",
        cost: "600,00"
      }
    ],
    options: [
      {
        id: 1,
        title: "Espaço grande / Salas combinadas",
        cost: 55.0
      },
      {
        id: 2,
        title: "Mude os moveis / pisos / parede",
        cost: 88.0
      }
    ],
    roomTypes: [
      {
        id: 1,
        name: "Sala",
        cost: "100,33"
      },
      {
        id: 2,
        name: "Jantar",
        cost: "100,33"
      },
      {
        id: 3,
        name: "Sala/jantar",
        cost: "100,33"
      },
      {
        id: 4,
        name: "Terraço ",
        cost: "100,33"
      },
      {
        id: 5,
        name: "Copa ",
        cost: "100,33"
      },
      {
        id: 6,
        name: "Quarto ",
        cost: "100,33"
      }
    ],
    roomDesignCategories: [
      {
        id: 1,
        category: "modern"
      },
      {
        id: 2,
        category: "classic"
      }
    ],
    roomDesignStyles: [
      {
        id: 1,
        roomDesignCategoryID: 1,
        src:
          "https://d4qwptktddc5f.cloudfront.net/easy_thumbnails/thumbs_IDPHOTOInterior-Design-Rapt-Studio-Goop-Santa-Monica-20200625_Rapt_Goop_Revised2-01.jpg.500x0_q85_crop-smart.jpg"
      },
      {
        id: 2,
        roomDesignCategoryID: 1,
        src:
          "https://www.lovehappensmag.com/blog/wp-content/uploads/2019/03/Gulla-Jonsdottir_NYPENTHOUSE_1-1000x660.jpg"
      },
      {
        id: 3,
        roomDesignCategoryID: 1,
        src:
          "https://www.thespruce.com/thmb/cL2LbkXuh-b-HwVopO1Dp4nAJYo=/2121x1193/smart/filters:no_upscale()/modern-living-room-1036309800-2eacf87caa1d4201b024d30a806d02b5.jpg"
      },
      {
        id: 4,
        roomDesignCategoryID: 2,
        src:
          "https://cdnassets.hw.net/f0/85/5e94add64c1192c59eb197712582/1214bc6d36374c1cb0e378a13c168fc0.jpg"
      },
      {
        id: 5,
        roomDesignCategoryID: 2,
        src:
          "https://archello.s3.eu-central-1.amazonaws.com/images/2019/05/24/Modern-Classic-House-Interior-Design-4.1558704276.0871.jpg"
      },
      {
        id: 6,
        roomDesignCategoryID: 2,
        src:
          "https://www.bocadolobo.com/en/inspiration-and-ideas/wp-content/uploads/2017/07/Main-Line-House-by-jayne-design-studio.jpg"
      }
    ],
    // roomReferences: [],
    selectedImage: {},
    references: references,
    totalCost: 0.0
  },
  getters: {
    // roomType: state => state.roomType,
    // roomOptions: state => state.roomOptions,
    typeList: state => state.typeList,
    options: state => state.options,
    roomTypes: state => state.roomTypes,
    roomDesignStyles: state => state.roomDesignStyles,
    roomDesignCategories: state => state.roomDesignCategories,
    // propertyStyle: state => state.propertyStyle,
    // roomReferences: state => state.roomReferences,
    selectedImage: state => state.selectedImage,
    references: state => state.references,
    totalCost: state => state.totalCost
  },
  mutations: {
    SET_COST(state, value) {
      state.totalCost = value;
      // Vue.set(state.totalCost, value);
    },

    SET_IMAGE_FOR_REF(state, payload) {
      state.selectedImage = payload;
    }
  },
  actions: {
    SET_COST: (context, value) => {
      context.commit("SET_COST", value);
    },

    SET_IMAGE_FOR_REF: (context, payload) => {
      context.commit("SET_IMAGE_FOR_REF", payload);
    }
  }
};

<template>
  <div class=" container-fluid fundo">
    <div class="container">
      <div class="row-sem-fundo">
          <PageHeader
            class="veto-sans-bold"
            tag="h3"
            title="E-Commerce"
            title_color="text-yellow"
            sub_title=""
            tipo_titulo="titulo"
          />
      </div>
      <div class="row">
        <div class="col-12">
          <div class="font-size-10">
            <p class="veto-sans-bold fonte-cinza">
                Entregamos modelagens de objetos, renders para utilização em e-commerce, catálogos e mídias socias.<br>
                Veja abaixo alguns exemplos dos serviços que executamos.
            </p>
          </div>
        </div>
        <div class="col-12 veto-sans-medium">
          <PageHeader
            class="bold"
            tag="h5"
            title="Solicite um orçamento por email <a href='mailto:leo@fullfreela.com' class='text-yellow veto-sans-bold'><strong>leo@fullfreela.com</strong></a>"
            title_color=""
            sub_title=""
            tipo_titulo="titulo-caixa"
          />
        </div>
      </div>
      <div class="row sub-heading-row">
        <div class="col-12">
          <div class=" d-flex flex-column align-items-left">
            <ServiceCard
              title="Render Silos"
              text="São feitos dois renders do objeto com uma luz de estúdio"
              class="box-ecommerce"
              src="https://static.wixstatic.com/media/6976e9_928b440dc7824a13a8741933d35e7bbe~mv2.jpg/v1/fill/w_729,h_413,al_c,q_80,usm_0.66_1.00_0.01/Cadeira_View.webp"
            />
            <ServiceCard
              title="Giro 360º"
              text="É um giro de 360 graus do objeto, feito com 12 ou 24 imagens e entregue em GIF."
              class="box-ecommerce"
              src="https://static.wixstatic.com/media/6976e9_89e676c7422f4f5881ac09d5e2375eef~mv2.gif"
            />
          </div>
        </div>
      </div>
      <div class="row mt-4 pb-5">
        <div class="col-12 mb-2">
          <PageHeader
            class="veto-sans-bold "
            tag="h1"
            title="Cenas"
            title_color="text-yellow"
            sub_title=""
          />
          <p class="font-size-20 veto-sans-medium" id="div-subtitle-cenas">
            Criamos cenas com os móveis e objetos, gerando imagens para serem
            usadas no e-commerce, catálogos e mídias sociais.
          </p>
        </div>
        <div class="container mt-3">
          <lightbox :items="images"></lightbox>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import PageHeader from "@/components/PageHeader";
import ServiceCard from "@/components/cards/ServiceCard";
import img1 from "@/assets/images/mesa-lado-de-fora.webp";
import img2 from "@/assets/images/mesa-lado-de-fora2.webp";
import img3 from "@/assets/images/mesacompc.webp";
import img4 from "@/assets/images/mesacompcdesligado.webp";
import img5 from "@/assets/images/mesacomvinho.webp";
import img6 from "@/assets/images/mesaeflor.webp";
import img7 from "@/assets/images/piscina.webp";

export default {
  components: {
    PageHeader,
    ServiceCard
  },
  data() {
    return {
      // images: [img1]
      images: [img1, img2, img3, img4, img5, img6, img7]
    };
  }
};
</script>

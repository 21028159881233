import "../main.js";
import Vue from "vue";
import store from "../store";

import VueRouter from "vue-router";
import Layout from "../views/Layout.vue";
import Home from "../views/Pages/Home.vue";
import Portifolio from "../views/Pages/Portifolio.vue";
import Servicos from "../views/Pages/Servicos.vue";
import DecoradoOrcamento from "../views/Pages/DecoradoOrcamento.vue";
import Decorado from "../views/Pages/DecoradoIntro.vue";
import ComoFunciona from "../views/Pages/ComoFunciona.vue";
import BoasPraticas from "../views/Pages/BoasPraticas.vue";
import Precos from "../views/Pages/Precos.vue";
import Contato from "../views/Pages/Contact.vue";

import StepOne from "../views/steps/Step-1.vue";

import StepTwo from "../views/steps/Step-2.vue";

import StepThree from "../views/steps/Step-3.vue";

import StepFour from "../views/steps/Step-4.vue";

import StepFive from "../views/steps/Step-5.vue";

import StepSix from "../views/steps/Step-6.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "",
    name: "orcamento",
    component: Layout,
    children: [
      {
        path: "/", redirect: 'passo_1',
      },
      {
        path: "passo_1",
        name: "Step-1",
        component: StepOne,
        meta: {
          step: 1,
          nav: false
        }
      },
      {
        path: "passo_2",
        name: "Step-2",
        component: StepTwo,
        meta: {
          step: 2,
          nav: false
        }
      },
      {
        path: "passo_3",
        name: "Step-3",
        component: StepThree,
        meta: {
          step: 3,
          nav: false
        }
      },
      {
        path: "passo_4",
        name: "Step-4",
        component: StepFour,
        meta: {
          step: 4,
          nav: false
        }
      },
      {
        path: "passo_5",
        name: "Step-5",
        component: StepFive,
        meta: {
          step: 5,
          nav: false
        }
      },
      {
        path: "passo_6",
        name: "Step-6",
        component: StepSix,
        meta: {
          step: 6,
          nav: false
        }
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  linkExactActiveClass: "active",
  routes,
  scrollBehavior(to, from, savedPosition) {
    return {
      x: 0,
      y: 0,
      behavior: "smooth"
    };
  }
});

export default router;

<template>
  <div class="row pb-5">
    <div
      class="col-6 col-lg-4 col-step4"
      data-toggle="modal"
      data-target="#refModal"
      v-for="reference in images"
      :key="reference.id"
      @click="openModal(reference)"
      :id="'ref-container-' + reference.id"
    >
      <div class="mb-4">
        <img
          class="img-responsive scale-in-center options-imgs-step4"
          :class="[wasSelected(reference.id) ? 'isSelected' : '']"
          :src="require('../assets/' + reference.src)"
          width="100%"
          height="200"
        />
      </div>
    </div>
    <hr />
    <portal to="modals">
      <ReferenceModal m_id="refModal" />
    </portal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ReferenceModal from "@/components/modals/ReferenceModal";

export default {
  components: {
    ReferenceModal
  },
  data() {
    return {
      images: [],
      image_id: null
    };
  },
  computed: {
    ...mapGetters([
      "jobs",
      "roomType",
      "selectedImage",
      "roomReferences",
      "references"
    ])
  },
  created() {
    this.loadRefs();
  },
  mounted() {
    this.$eventHub.$on("refImg", data => {
      this.image_id = data.id;
      this.loadReferenceBySelectedImg();
    });
  },
  methods: {
    ...mapActions(["SET_REFERENCE_TO_ROOM"]),
    openModal(x) {
      console.log("666");
      this.$eventHub.$emit("refModal", x);
    },
    loadRefs() {
      let ref = this.roomType.find(refs => {
        return refs.image_id === this.jobs[0].id;
      });
      console.log("refs imgs = ", ref);
      this.images = this.references.filter(refs => {
        return refs.roomCategoryType === ref.roomTypeId;
      });
    },
    loadReferenceBySelectedImg() {
      let ref = this.roomType.find(refs => {
        return refs.image_id === this.image_id;
      });

      this.images = this.references.filter(refs => {
        return refs.roomCategoryType === ref.roomTypeId;
      });
    },
    wasSelected(id) {
      return this.roomReferences.some(ref => {
        return ref.image_id === this.selectedImage.id && ref.refId === id;
      });
    }
  }
};
</script>

<style scoped></style>

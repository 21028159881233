<template>
  <div class="container-fluid fundo">
    <div class="container">
      <div class="row">
        <div class="col-12 row-sem-fundo">
          <PageHeader
            class="veto-sans-bold"
            tag="h3"
            title="Decorado Virtual"
            title_color="text-yellow"
            sub_title=""
            tipo_titulo="titulo"
          />
        </div>
        <div class="col-12">
          <div class="font-size-10 veto-sans-bold">
            <p class="subtitle-decorado">
              <strong
                >Coloque mobiliário super realista nas fotos dos seus imóveis e
                acelere as suas vendas.</strong
              >
            </p>
          </div>
        </div>
      </div>
      <div class="row mt-3 w-100 mb-5 pl-3">
        <div class="box-link mr-auto ml-0">
          <router-link class="nav-link" :to="{ name: 'Step-1' }">
            Saiba Mais
          </router-link>
        </div>
      </div>
      <div class="row mt-5 mb-5 pb-5">
        <div class="col-12">
          <twenty-twenty
            :before="require('../../assets/images/imgs_twenty/1A.jpg')"
            :after="require('../../assets/images/imgs_twenty/1B.jpg')"
            beforeLabel="Antes"
            afterLabel="Depois"
          />
        </div>
      </div>
      <div class="row mt-5 mb-5 pb-5">
        <div class="col-12">
          <twenty-twenty
            :before="require('../../assets/images/imgs_twenty/2A.jpg')"
            :after="require('../../assets/images/imgs_twenty/2B.jpg')"
            beforeLabel="Antes"
            afterLabel="Depois"
          />
        </div>
      </div>
      <div class="row mt-5 mb-5 pb-5">
        <div class="col-12">
          <twenty-twenty
            :before="require('../../assets/images/imgs_twenty/3A.jpg')"
            :after="require('../../assets/images/imgs_twenty/3B.jpg')"
            beforeLabel="Antes"
            afterLabel="Depois"
          />
        </div>
      </div>
      <div class="row mt-5 mb-5">
        <div class="col-12">
          <twenty-twenty
            :before="require('../../assets/images/imgs_twenty/4A.jpg')"
            :after="require('../../assets/images/imgs_twenty/4B.jpg')"
            beforeLabel="Antes"
            afterLabel="Depois"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <PageHeader
            class="veto-sans-bold py-4 px-5 title-prices"
            tag="h4"
            title="Valores"
            title_color="text-black"
            sub_title=""
            tipo_titulo="titulo-caixa"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-4 col-cards-planos">
          <div class="box-group">
            <img
              :src="require('../../assets/images/imagens_referencia/01.jpg')"
              alt="Explicação"
              srcset=""
              class="img-box"
            />
            <div class="box-descricao">
              <div class="veto-sans-bold">BASIC</div>
              <span class="subtitle-card-prices veto-sans-bold"
                >4 dias úteis</span
              >
              <br />
              <span class="subtitle-card-prices veto-sans-bold">1 Revisão</span>
              <p class="box-complemento veto-sans-bold">
                Mudança de de piso / paredes Ambientes Combinados
              </p>
              <p>
                <span class="veto-sans-bold">R$ </span>
                <strong class="veto-sans-bold">349</strong>
              </p>
              <hr class="box-hr" />
            </div>
          </div>
        </div>
        <div class="col-12 col-md-4 col-cards-planos">
          <div class="box-group">
            <img
              :src="require('../../assets/images/imagens_referencia/02.jpg')"
              alt="Explicação"
              srcset=""
              class="img-box"
            />
            <div class="box-descricao">
              <div class="veto-sans-bold">PRO</div>
              <span class="subtitle-card-prices veto-sans-bold"
                >2 dias úteis</span
              >
              <br />
              <span class="subtitle-card-prices veto-sans-bold"
                >2 Revisões</span
              >
              <br />
              <span class="subtitle-card-prices veto-sans-bold"
                >Mudança de piso / paredes</span
              >
              <p class="box-complemento veto-sans-bold">
                Ambientes Combinados
              </p>
              <p>
                <span class="veto-sans-bold">R$ </span>
                <strong class="veto-sans-bold">549</strong>
              </p>
              <hr class="box-hr" />
            </div>
          </div>
        </div>
        <div class="col-12 col-md-4 col-cards-planos">
          <div class="box-group">
            <img
              :src="require('../../assets/images/imagens_referencia/03.jpg')"
              alt="Explicação"
              srcset=""
              class="img-box"
            />
            <div class="box-descricao">
              <div class="veto-sans-bold">PREMIUM</div>
              <span class="subtitle-card-prices veto-sans-bold">1 dia</span>
              <br />
              <span class="subtitle-card-prices veto-sans-bold">3 Revisão</span>
              <br />
              <span class="subtitle-card-prices veto-sans-bold"
                >Mudança de de piso / paredes
              </span>
              <br />
              <span class="subtitle-card-prices veto-sans-bold"
                >Ambientes Combinados</span
              >
              <p class="box-complemento"></p>
              <p>
                <span class="veto-sans-bold">R$ </span>
                <strong class="veto-sans-bold">849</strong>
              </p>
              <hr class="box-hr" />
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-5 pb-5 row-btn-mob">
        <div class="box-link">
          <router-link class="nav-link" :to="{ name: 'Step-1' }">
            Saiba Mais
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";

export default {
  components: {
    PageHeader
  }
};
</script>

<style scoped>
.img-box {
  width: 100%;
}

.box-descricao {
  position: relative;
  background-color: #292828;
  color: white;
  padding: 16px 48px 10px 48px;
  font-weight: bold;
  font-size: 18px;
}

.box-descricao div {
  position: absolute;
  background-color: #f2b130;
  padding: 0.5vh 11vh 0.5vh 1.5vh;
  font-weight: bold;
  text-align: left;
  font-size: 18px;
  right: 0px;
  top: -2.5vh;
}

.box-descricao p.box-complemento {
  color: #807e7b;
}

.box-descricao p span {
  font-size: 12px;
  top: -1.5vh;
  position: relative;
}
.box-descricao p strong {
  font-size: 24px;
}

.box-hr {
  border: 2px solid #f2b130;
}
.box-link {
  background-color: #0c702a;
  padding: 1vh 4vh 1vh 4vh;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  margin: 0 auto;
}
.box-link a {
  color: white;
}
</style>

<template>
  <div class="fundo container-fluid">
    <div>
      <NumNav />
      <div class="container">
        <transition name="slide-in" mode="out-in">
          <router-view />
        </transition>
      </div>
    </div>
    <portal-target name="modals" />
  </div>
</template>

<script>
import NumNav from "@/components/navigation/NumberNavigation";
export default {
  components: {
    NumNav
  },
  mounted() {
    this.$router.push({ name: "Step-1" });
  }
};
</script>

<style lang="scss">
.step-by-step-pages {
  width: 100%;
  // display: flex;
}
</style>

<template>
  <div class="div-principal-mob" style="padding-top:5em;">
    <PageHeader
      tag="h1"
      title_color="text-orange"
      title="Escolha uma imagem de referência para casa sala"
      sub_title="Primeiro selecione a sala e depois escolha a referencia"
    />
    <!-- span debaixo não vai ser exibido, o mesmo só serve para executar a função -->
    <span v-if="jobs.length < 1">{{ step1() }}</span>
    <div class="row">
      <div class="col-12">
        <div class="scrolling-wrapper">
          <div
            class="col-md-3 p-0 mr-4 mb-4 job-img-ref-container"
            v-for="(job, index) in jobs"
            :key="job.id"
            @click.prevent="selectToRef(job)"
            :id="'img-ref-container-' + job.id"
          >
            <div class="overlay" v-if="haveReference(job.id)">
              <box-icon
                name="select-multiple"
                class="icon"
                size="lg"
              ></box-icon>
            </div>
            <img
              class="img-responsive"
              :id="'job-ref-img' + job.id"
              :class="[isSelected(index) ? 'isSelected' : '']"
              :src="job.src"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="scrollable-container mt-4">
      <ReferenceImageContainer />
    </div>
    <div class="row mt-4 pb-5">
      <div class="col-12 d-flex justify-content-between">
        <button
          class="btn btn-primary "
          @click="$router.push({ path: 'passo_3' })"
        >
          Voltar
        </button>
        <button
          class="btn btn-primary"
          @click="$router.push({ path: 'passo_5' })"
        >
          Proximo
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import PageHeader from "@/components/PageHeader";
import ReferenceImageContainer from "@/components/ReferenceImageContainer";

export default {
  components: {
    PageHeader,
    ReferenceImageContainer
  },
  data() {
    return {
      hasImage: false
    };
  },
  computed: {
    ...mapGetters(["jobs", "selectedImage", "roomReferences"])
  },
  created() {
    this.isSelectedMounted();
    this.isSelected(0);
  },
  methods: {
    ...mapActions(["SET_IMAGE_FOR_REF"]),
    step1() {
      this.$router.push({ path: "passo_1" });
    },
    selectToRef(data) {
      this.$eventHub.$emit("refImg", data);
      this.SET_IMAGE_FOR_REF(data);
      this.actionShowSelection(data.id);
    },
    objEmptyCheck() {
      let x =
        Object.keys(this.selectedImage).length !== 0 &&
        this.selectedImage.constructor === Object;
      console.log("do i have img?", x);
      this.hasImage = x;
    },
    isSelectedMounted() {
      this.selectToRef(this.jobs[0]);
    },
    isSelected(index) {
      return this.jobs[index].id === this.selectedImage.id;
    },
    displayErrorOnCards() {
      let jobIds = this.jobs.map(job => job.id);
      let roomReferences = this.roomReferences.map(ref => ref.image_id);
      let unSelectedJobId = this.$masterHelper.compare_arrays(
        jobIds,
        roomReferences
      );
      jobIds.forEach(id => {
        document.getElementById("job-ref-img" + id).classList.remove("error");
      });
      unSelectedJobId.forEach(unselected => {
        document
          .getElementById("job-ref-img" + unselected)
          .classList.add("error");
      });
      unSelectedJobId.length > 0
        ? document
            .getElementById("img-ref-container-" + unSelectedJobId[0])
            .scrollIntoView({ behavior: "smooth" })
        : "";
    },
    haveReference(id) {
      return this.roomReferences.some(refs => {
        return refs.image_id === id;
      });
    },
    actionShowSelection(id) {
      if (this.haveReference(id)) {
        let refID = this.roomReferences.find(ref => {
          return ref.image_id === id;
        }).refId;
        let element = document.getElementById("ref-container-" + refID);
        console.log("elements contains class", element);
        if (element.classList.contains("jello-vertical")) {
          this.action(element, true);
        } else {
          console.log("elements doesnt contains class", element);
          this.action(element, false);
        }
      }
    },
    action(element, x) {
      setTimeout(() => {
        if (x) {
          element.classList.remove("jello-vertical");
          element.classList.add("jello-vertical");
        } else {
          element.classList.add("jello-vertical");
          setTimeout(() => {
            element.classList.remove("jello-vertical");
          }, 600);
        }
      }, 400);
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.meta.nav) {
      next();
    } else if (to.meta.nav === false) {
      if (to.meta.step < 4) {
        next();
      } else {
        if (this.jobs.length === this.roomReferences.length) {
          next();
        } else {
          this.displayErrorOnCards();
          this.$notify.warning({
            position: "top right",
            title: "Espere",
            msg:
              "Parece que você esqueceu de selecionar uma referencia para um dos jobs.",
            timeout: 6000
          });
        }
      }
    }
  }
};
</script>

<style lang="scss">
.isSelected {
  border: 2px solid #e07a05;
  box-shadow: 0px 0px 12px -3px #e07a05;
}
.error {
  border: 1px solid red;
}
.job-img-ref-container {
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  .overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 233, 70, 0.733);
    position: absolute;
    height: 100% !important;
    width: 100% !important;
  }
}

.scrollable-container {
  padding: 0.5em;
  overflow-y: scroll;
  overflow-x: hidden;

  height: 500px;
  &::-webkit-scrollbar {
    width: 10px !important; /* width of the entire scrollbar */
  }
  &::-webkit-scrollbar-track {
    background: rgb(223, 223, 223); /* color of the tracking area */
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgb(136, 136, 136); /* color of the scroll thumb */
    border-radius: 10px; /* roundness of the scroll thumb */
    border: 2px solid rgb(136, 136, 136); /* creates padding around scroll thumb */
  }
}
</style>

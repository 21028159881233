<template>
  <span>
    <div class="card thumb-card slide-in-fwd-center">
      <div
        class="thumb"
        ref="thumb"
        @click="open('opening img')"
        :title="data.name"
      >
        <img :src="data.src" alt="" />
        <span class="card-backdrop"></span>
      </div>
      <div class="info">
        <div class="info-header" @click="removeFile(data.id)">
          <box-icon name="x" size="16px"></box-icon>
        </div>
        <div class="data" :title="data.name">
          <div class="mt-0 mb-0">
            {{ data.name.substr(0, 15) }} <span class="bold-text">...</span>
          </div>
          <div class="mt-0 mb-0">{{ data.size }}</div>
        </div>
      </div>
    </div>
  </span>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "Thumbnail",
  props: ["data", "index"],
  mounted() {},
  methods: {
    ...mapActions(["REMOVE_JOB"]),
    open(e) {
      console.log(e);
    },
    removeFile(id) {
      // this.files.splice(key, 1);
      // criar event para remover
      //evento()=> this.files.splice(key, 1);
      console.log("emitting...", this.$props.index);
      this.$eventHub.$emit("remove", this.$props.index);

      // this.$NavValidation($route.name);
      this.REMOVE_JOB(id);
      // this.getImagePreviews();
    }
  }
};
</script>

<style lang="scss" scoped>
.thumb-card {
  display: flex;
  flex-direction: row !important;
  align-items: center;
  // height: 100%;
  .thumb {
    position: relative;
    width: 250px !important;
    height: 0100px !important;
    img {
      height: 100%;
      width: 100%;
    }
  }
  .info {
    .info-header {
      display: flex;
      justify-content: flex-end;
      width: 7%;
      height: 20%;

      position: absolute;
      top: 0;
      right: 0;
    }
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    // align-items: center;
    flex-direction: column !important;
  }
}
</style>

export const FileController = () => {
  const getExtention = file => {
    return file.substring(file.lastIndexOf(".") + 1);
  };
  const listForImage = file => {
    var forImage = ["jpeg", "jpg", "png", "gif", "webp", "tiff"];
    var canUpload = forImage.some(ext => {
      return ext === getExtention(file);
    });
    return canUpload;
  };
  const listForExtraFile = file => {
    var forExtraFile = [
      "ai",
      "psd",
      "cad",
      "pdf",
      "pptx",
      "doc",
      "docx",
      "jpg",
      "jpeg",
      "png",
      "tiff"
    ];
    var canUpload = forExtraFile.some(ext => {
      return ext === getExtention(file);
    });
    return canUpload;
  };
  return {
    getExtention,
    listForImage,
    listForExtraFile
  };
};

<template>
  <div class="fundo">
    <div class="container-fluid">
      <div class="container py-4 veto-sans-bold-all">
        <PageHeader
            class=""
            tag="h3"
            title="Boas Práticas"
            title_color="text-yellow"
            sub_title="Os jobs devem seguir alguns critérios, que facilitam a sua organização e a confeência do time de QA"
            tipo_titulo="titulo"
        />
      </div>
    </div>
    <!-- escala -->
    <div class="container-fluid row-fundo">
      <div class="container">
        <div class="row mt-5">
          <div class="col-12">
            <PageHeader
              class="titulos-boas-praticas veto-sans-bold"
              tag="h4"
              title="Escala e eixo 0,0,0"
              title_color=""
              sub_title=""
              tipo_titulo="titulo-caixa"
            />
          </div>
          <div class="col-12 font-size-10">
            <p class="mb-5 veto-sans-light">
              Certifique-se que as unidade de medidas de todos os Jobs estão em centímetros.<br>
              Antes de começar vá no Unity Setup do Max e configure corretamente.<br>
              Alguns jobs podem exigir outras unidades de medidas, fique atento!
            </p>
            <div class="mb-5">
              <img
                :src="require('../../assets/images/explicacao.png')"
                alt="Explicação"
                srcset=""
                width="75%"
                class="img-boas-praticas"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- modelagem de móveis -->
    <div class="container-fluid">
      <div class="container">
        <div class="row mt-5">
          <div class="col-12">
            <PageHeader
              class="titulos-boas-praticas veto-sans-bold"
              tag="h4"
              title="Modelagem de móveis"
              title_color=""
              sub_title=""
              tipo_titulo="titulo-caixa"
            />
          </div>
          <div class="col-12 font-size-10 mt-2 veto-sans-light-all">
            <p>
              Esses Jobs podem ser de móveis ou de objetos de decoração.
            </p>
            <p>
              Crie um layer chamado MOB e inclua o seu modelo.<br>
              O modelo deve ter o pivô centralizado e na base do objeto.
            </p>
            <p class="mb-4">
              Mova o objeto para o centro das coordenadas x,y,z - 0,0,0
            </p>
            <div class="mb-4">
              <img
                :src="require('../../assets/images/explicacao2.png')"
                alt="Explicação"
                srcset=""
                width="75%"
                class="img-boas-praticas"
              />
            </div>
          </div>
          <div class="col-12 font-size-10">
            <p class="mt-5 veto-sans-light">
              O modelo deve estar atachado com um material multi/sub, nomeado corretamente em cada ID.
            </p>
            <div class="mb-5 mt-3">
              <img
                :src="require('../../assets/images/explicacao3.png')"
                alt="Explicação"
                srcset=""
                width="75%"
                class="img-boas-praticas"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

      <!-- modelagem de arquitetura -->
    <div class="container-fluid row-fundo">
      <div class="container">
        <div class="row mt-5">
          <div class="col-12">
            <PageHeader
              class="titulos-boas-praticas veto-sans-bold"
              tag="h4"
              title="Modelagem de Arquitetura"
              title_color=""
              sub_title=""
              tipo_titulo="titulo-caixa"
            />
          </div>
          <div class="col-12 font-size-10 veto-sans-light">
            <p>Esses jobs podem ser de arquitetura externa ou interna.</p>
            <p class="mb-4"> Crie um layer chamado ARCH e inclua todo o seu modelo.<br> Agrupe tudo e mova para o centro das coordenadas x,y,z - 0,0,0<br> Deixe o piso ou pavimento térreo na altura zero do eixo Z. </p>
            <div>
              <img
                :src="require('../../assets/images/explicacao4.png')"
                alt="Explicação"
                srcset=""
                width="75%"
                class="img-boas-praticas"
              /><br>
              <p class="mt-2"><span class="veto-sans-bold">Todos os ojbetos devem estar nomeados e atachados por tipo de material. Ex, paredes, piso pedra, piso madeira,<br> pastilha piscina, forro, esquadrias entre outros.</span></p>
            </div>
          </div>
          <div class="col-12 mt-5">
              <div class="veto-sans-light">
                <PageHeader
                  class="titulos-boas-praticas veto-sans-bold"
                  tag="h4"
                  title="Plantas Baixas"
                  title_color=""
                  sub_title=""
                  tipo_titulo="titulo-caixa"
                />
              Nas plantas precisamos somente de um arquivo em alta resolução em TIFF com o maior tamanho tendo no<br> mínimo 6.000 pixels.
            </div>
          </div>
          <div class="col-12 mt-5">
            <div class="veto-sans-light">
              <PageHeader
                class="titulos-boas-praticas veto-sans-bold"
                tag="h4"
                title="Estudios de Render"
                title_color=""
                sub_title=""
                tipo_titulo="titulo-caixa"
              />
              Abra o arquivo Estudio para renderizar o móvel ou objeto modelado.<br>
              Jobs de arquitetura não precisam ser renderizados.<br>
              Mova o modelo para o centro das coordenadas x,y,z - 0,0,0.
            </div>
          </div>
          <div class="col-12 mt-4 mb-5">
            <div class="">
              <p class="mb-1 veto-sans-light">Você pode baixar o arquivo dentro da Plataforma.</p><br>
            <img
                :src="require('../../assets/images/explicacao5.png')"
                alt="Explicação"
                srcset=""
                width="75%"
                class="img-boas-praticas"
              /><br>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Renderize as cameras -->
    <div class="container-fluid">
      <div class="container">
        <div class="row">
          <div class="col-12 mt-5">
            <PageHeader
              class="titulos-boas-praticas veto-sans-bold"
              tag="h4"
              title="Renderize as câmeras Top e Side, salvando cada uma<br> em um arquivo conforme especificações abaixo:"
              title_color=""
              sub_title=""
              tipo_titulo="titulo-caixa"
            />
          </div>
          <div class="col-12 font-size-10 ">
            <p class="veto-sans-light">
              <span class="veto-sans-bold">Tamanho:</span> 1920 x 1444 pixels
            </p>
            <p class="veto-sans-light">
              <span class="veto-sans-bold">Formato:</span> JPG
            </p>
            <p class="veto-sans-light">
              <span class="veto-sans-bold">Nomeclatura</span>
            </p>
            <p class="veto-sans-light">
              jobname_Front.jpg
            </p>
            <p class="veto-sans-light">
              jobname_Side.jpg
            </p>
          </div>
          <div class="col-12 mt-2 d-flex justify-content-center flex-wrap">
            <div class="p-2 w-50 div-cameras-funciona">
              <p align="center">
                <span class="veto-sans-bold">Câmera Front</span><br>
                <img
                  :src="require('../../assets/images/explicacao6.png')"
                  alt="Explicação"
                  srcset=""
                  width="100%"
                />
              </p>
            </div>
            <div class="p-2 w-50 div-cameras-funciona">
              <p align="center">
                <span class="veto-sans-bold">Câmera Side</span><br>
                <img
                  :src="require('../../assets/images/explicacao7.png')"
                  alt="Explicação"
                  srcset=""
                  width="100%"
                />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Como enviar o arquivo -->
    <div class="container-fluid row-fundo">
      <div class="container">
        <div class="row mt-4">
          <div class="col-12">
            <PageHeader
              class="titulos-boas-praticas veto-sans-bold"
              tag="h4"
              title="Como enviar os arquivos?"
              title_color=""
              sub_title=""
              tipo_titulo="titulo-caixa"
            />
          </div>
          <div class="col-12 font-size-10">
            <p class="veto-sans-light">
              O envio é bem fácil. Na própria página do Job, o Freelancer verá um campo para envio.<br>
              Crie um arquivo ZIP ou RAR com o modelo 3d e os dois renders.<br>
              Quando houver texturas você deve enviá-las também.
            </p>
            <p class="veto-sans-bold">
              A cada etapa de revisão você enviará os arquivos do mesmo jeito.
            </p>

          </div>
          <div class="col-12 mt-4 mb-5">
            <div class="">
              <img
                  :src="require('../../assets/images/explicacao8.png')"
                  alt="Explicação"
                  srcset=""
                  width="75%"
                  class="img-boas-praticas"
                /><br>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";

export default {
  components: {
    PageHeader
  }
};
</script>

<style>
.titulos-boas-praticas h4{
  font-weight: bold;
  padding-right: 5vw;
}
</style>

<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import icon from "@/assets/logo.png";
export default {
  mounted() {
    var link = document.querySelector("link[rel~='icon']");
    link.href = icon;
  }
};
</script>
<style lang="scss" scoped></style>

<template>
  <div class="container-fluid" id="fundo-footer" style="background-color:#414141">
    <div class="container py-5" style="color:#fff">
      <div class="row inner-container">
        <div class="col-12">
          <img class="f-logo" :src="logo" />
        </div>
        <div class="left-side col-12 col-md-8" id="div-redes-sociais">
          <div class="inner-side-icons mt-5">
            <a href="https://www.youtube.com/channel/UCB1o_4Ri8lmQGFzOJgF8kAw" target="_blank">
              <span type="button" class="side-icon">
                <box-icon color="white" type="logo" name="youtube"></box-icon>
              </span>
            </a>
            <span type="button" class="side-icon">
              <box-icon color="white" type="logo" name="pinterest"></box-icon>
            </span>
            <a href="https://www.instagram.com/fullfreela/" target="_blank">
              <span type="button" class="side-icon">
                <box-icon color="white" type="logo" name="instagram"></box-icon>
              </span>
            </a>
            <a href="https://twitter.com/fullfreela" target="_blank">
              <span type="button" class="side-icon">
                <box-icon color="white" type="logo" name="twitter"></box-icon>
              </span>
            </a>
            <a href="https://www.facebook.com/fullfreela/" target="_blank">
              <span type="button" class="side-icon">
                <box-icon color="white" type="logo" name="facebook"></box-icon>
              </span>
            </a>
          </div>
          <div style="font-size:14px" class="mt-5 veto-sans-light-all" id="div-infos-footer">
            <p class="mb-1">
              FullFreela Tecnologia e Serviços Digitais © 2020 - Todos os direitos reservados.
            <!-- CNPJ: nº 00.000.555/0001-00 -->
            <!-- <p class="mb-1">
              Todos os direitos reservados.
            </p> -->
            <p class="mb-1">
              <a class="text-white" href="https://www.google.com/maps/place/Mint+Studios/@-23.5971613,-46.6834211,15z/data=!4m2!3m1!1s0x0:0x20df4d8427c312b0?sa=X&ved=2ahUKEwimyK-Qla3uAhWDGbkGHTVqByUQ_BIwE3oECCIQBQ" target="_blank">
                R. Alvorada, 1289 - Cj 1110 - Vila Olímpia, São Paulo - SP, 04550-070
              </a>
            </p>
            <p>
              © Desenvolvido por <a href="https://www.011brasil.com.br/" target="_blank"  class="text-white">011Brasil.</a>
            </p>
          </div>
        </div>
        <div class="right-side col-12 col-md-4">
          <div class="d-flex">
            <box-icon color="white" name="mobile-alt"></box-icon>
            <p class="ml-3">
              <span class="veto-sans-bold">
                Atendimento <br />
                Central de Relacionamento <br />
              </span>
              <span class="veto-sans-light"><a href="tel:+551126143671" class="text-white">+55 11 2614-3671</a></span>
            </p>
          </div>
          <div class="d-flex mt-3">
            <box-icon color="white" name="mobile-alt"></box-icon>
            <p class="ml-3">
              <span>
                Sac
              </span>
              <span class="veto-sans-light"><a href="tel:+551126143671" class="text-white">+55 11 2614-3671</a></span>
            </p>
          </div>
          <div class="d-flex mt-3">
            <box-icon color="white" name="mobile-alt"></box-icon>
            <p class="ml-3">
              <span>
                Ouvidoria
              </span>
              <span class="veto-sans-light"><a href="tel:+551126143671" class="text-white"> +55 11 2614-3671</a></span>
            </p>
          </div>
          <div class="d-flex mt-3">
            <box-icon color="white" name="laptop"></box-icon>
            <p class="ml-3">
              <span class="veto-sans-light">
                <a href="mailto:contato@fullfreela.com" class="link-contato text-white">
                  contato@fullfreela.com
                </a>
              </span>
            </p>
          </div>
        </div>
        <div style="font-size:14px" class="mt-5 veto-sans-light-all col-12 d-none" id="div-infos-footer-mob">
            <p class="mb-1">
              FullFreela Tecnologia e Serviços Digitais © 2020 - Todos os direitos reservados.
            <!-- CNPJ: nº 00.000.555/0001-00 -->
            <!-- <p class="mb-1">
              Todos os direitos reservados.
            </p> -->
            <p class="mb-1">
              <a class="text-white" href="https://www.google.com/maps/place/Mint+Studios/@-23.5971613,-46.6834211,15z/data=!4m2!3m1!1s0x0:0x20df4d8427c312b0?sa=X&ved=2ahUKEwimyK-Qla3uAhWDGbkGHTVqByUQ_BIwE3oECCIQBQ" target="_blank">
                R. Alvorada, 1289 - Cj 1110 - Vila Olímpia, São Paulo - SP, 04550-070
              </a>
            </p>
            <p>
              © Desenvolvido por <a href="https://www.011brasil.com.br/" target="_blank"  class="text-white">011Brasil.</a>
            </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      logo: require("../../assets/images/logo/logo_fullfreela_white.svg")
    };
  },
  mounted() {
    document.getElementById("overlay").style.display = "block";
  },
  beforeRouteLeave(to, from, next) {
    document.getElementById("overlay").style.display = "none";
    next();
  }
};
</script>

<style scoped>

</style>

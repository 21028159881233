import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Lightbox from "@morioh/v-lightbox";
import store from "./store";
import PortalVue from "portal-vue";
import vSelect from "vue-select";
import VueNotify from "vuejs-notify";
import { IdController } from "./assets/controllers/IdController";
import { PriceController } from "./Helpers/PriceController.js";
import { FileController } from "./Helpers/FileController.js";
import { NavValidation } from "./Helpers/NavigationValidations.js";
import { masterHelper } from "./Helpers/Master.js";

import "jquery";
import "popper.js";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/fullfreela.scss";
import "./assets/css/animations.scss";
// import "./assets/css/fonteVeto.scss";
// import "vue-select/dist/vue-select.css";
import "boxicons";
import "./assets/css/fonts/trust-font.css";
import "@morioh/v-lightbox/dist/lightbox.css";
import 'vue-twentytwenty/dist/vue-twentytwenty.css';
import TwentyTwenty from 'vue-twentytwenty';
// import emailjs from 'emailjs-com';



Vue.config.productionTip = false;

Vue.prototype.$eventHub = new Vue();
Vue.prototype.$IdController = IdController;
Vue.prototype.$priceHelper = PriceController;
Vue.prototype.$fileController = FileController;
Vue.prototype.$NavValidation = NavValidation;
Vue.prototype.$masterHelper = masterHelper;

Vue.use(PortalVue);

Vue.use(VueNotify, {
  closeOnClick: true
});
// Vue.component("v-select", vSelect);
Vue.use(Lightbox);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");

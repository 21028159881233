<template>
  <div>
    <div class="container">
      <PageHeader
        class="bold-text"
        tag="h1"
        title="Vamos começar?"
        title_color="text-orange"
        sub_title=""
      />
    </div>
    <div class="row">
      <div class="container">
        <div class="font-size-20">
          <p> Preços -
            Somos uma plataforma de serviços de
            <strong> CGI (Computação Gráfica)</strong>
          </p>

        </div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="container">
        <PageHeader
          class="bold-text"
          tag="h2"
          title="Freelancers"
          title_color="text-orange"
          sub_title=""
        />
        <PageHeader
          class="bold-text"
          tag="h5"
          title="Como funciona"
          title_color=""
          sub_title=""
        />
        <div>
          <p class="font-size-20">
            Nossos Jobs ficarão disponíveis em nosso Marketplace.
          </p>
          <p class="font-size-20">
            O Freelancer terá três etapas para fazer o Job. Na primeira etapa
            envia seu job e caso não esteja de acordo, receberá uma revisão
            dentro da nossa plataforma com o que deve ser corrigido.
          </p>
        </div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="container">
        <PageHeader
          class="bold-text"
          tag="h5"
          title="Pagamento"
          title_color=""
          sub_title=""
        />
        <div class="font-size-20">
          <p>
            Você precisa criar uma conta no <strong>PayPal</strong> para receber
            seus pagamentos.
          </p>
        </div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="container">
        <PageHeader
          class="bold-text"
          tag="h5"
          title="Requesitos básicos"
          title_color=""
          sub_title=""
        />
        <PageHeader
          class=""
          tag="h5"
          title="Para iniciar em nossa plataforma você precisa seguir os seguintes pré-requisitos. "
          title_color="text-orange"
          sub_title=""
        />
        <div class="font-size-20">
          <p>
            <strong>Modelagem de Móveis:</strong> O job deve ser entregue no
            formato 3ds Max, versões 2018 à 2020.
          </p>
          <p>
            <strong> Modelagem de Arquitetura: </strong> O job deve ser entregue
            no formato 3ds Max, versões 2018 à 2020.
          </p>
          <p>
            <strong> ​Render:</strong> Formato TIFF, com no mínimo 6000 pixels
            de largura.
          </p>
          <p><strong> Materiais:</strong> Corona Render.</p>
          <p>
            <strong>Texturas: </strong>
            Formatos jpg, png, TIFF ou TGA.
          </p>
          <p>
            <strong>Plantas Baixas Decoradas:</strong>
            ​ Formato TIFF, com no mínimo 6000 pixels de largura.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";

export default {
  components: {
    PageHeader
  }
};
</script>

<style></style>

<template>
  <div class="container-fluid fundo">
    <div class="row-sem-fundo">
      <div class="container">
        <div class="row">
          <!-- <div class="col-12">
            <PageHeader
              class="bold-text"
              tag="h4"
              title="O que fazemos"
              title_color="text-yellow"
              sub_title=""
              tipo_titulo="titulo"
            />
          </div> -->
          <div class="col-12 pt-4">
            <div class="font-size-15">
              <p class="veto-sans-light">
                Somos uma plataforma de serviços de
                <sapn class="veto-sans-bold"> CGI (Computação Gráfica)</sapn>
              </p>
              <p class="veto-sans-light">
                Faça seu cadastro como Freelancer e tenha acesso a Jobs variados,
                como modelagem 3D, arquitetura, renders e plantas baixas.
              </p>
              <p class="veto-sans-light">
                ​Preencha nosso cadastro e caso seja aprovado, enviaremos o link
                para você ativá-lo e criar seu login. É importante preencher o maior
                número de informações possíveis, incluindo seu portfólio. Todos as
                solicitações de <span class="veto-sans-bold">cadastro</span> passarão por uma análise, caso não seja
                aceita explicaremos o que você precisa e o ajudaremos a fazer parte
                da nossa comunidade.
              </p>
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-12 mb-3">
            <PageHeader
              class="veto-sans-bold"
              tag="h4"
              title="Freelancers"
              title_color="text-yellow"
              sub_title=""
              tipo_titulo="titulo"
            />
          </div>
          <div class="col-12 mt-4">
            <PageHeader
              class="titulos-como-funciona veto-sans-bold"
              tag="h4"
              title="Como funciona"
              title_color=""
              sub_title=""
              tipo_titulo="titulo-caixa"
            />
            <div class="font-size-15">
              <p class="veto-sans-light"> Nossos Jobs ficarão disponíveis em nosso Marketplace.</p>
              <p class="veto-sans-light">
                O Freelancer terá três etapas para fazer o Job. Na primeira etapa
                envia seu job e caso não esteja de acordo, receberá uma revisão
                dentro da nossa plataforma com o que deve ser corrigido.
              </p>
              <p class="veto-sans-light">
                ​Os valores ofertados dependem da complexidade do job e podem variar
                de acordo com cada empresa ofertante.
              </p>
              <p class="veto-sans-light">
                ​O pagamento é feito em até sete dias úteis após a aprovação do job,
                e recebe o valor pelo <span class="veto-sans-bold">PayPal</span>.
              </p>
              <p class="veto-sans-light"> ​Só use softwares licenciados. </p>
            </div>
          </div>
          <div class="col-12 mt-3">
            <div class="">
              <PageHeader
                class="titulos-como-funciona veto-sans-bold"
                tag="h4"
                title="Pagamento"
                title_color=""
                sub_title=""
                tipo_titulo ="titulo-caixa"
              />
              <div class="font-size-15">
                <p class="veto-sans-light">
                  Você precisa criar uma conta no <span class="veto-sans-bold">PayPal</span> para receber
                  seus pagamentos.
                </p>
              </div>
            </div>
          </div>
          <div class="col-12 mt-3">
            <div class="">
              <PageHeader
                class="titulos-como-funciona veto-sans-bold"
                tag="h4"
                title="Requesitos básicos"
                title_color=""
                sub_title=""
                tipo_titulo="titulo-caixa"
              />
              <PageHeader
                class="veto-sans-bold"
                tag="h5"
                title="Para iniciar em nossa plataforma você precisa seguir os seguintes pré-requisitos. "
                title_color="text-orange"
                sub_title=""
              />
              <div class="font-size-15">
                <p class="veto-sans-light">
                  <span class="veto-sans-bold">Modelagem de Móveis:</span> O job deve ser entregue no
                  formato 3ds Max, versões 2018 à 2020.
                </p>
                <p class="veto-sans-light">
                  <span class="veto-sans-bold"> Modelagem de Arquitetura: </span> O job deve ser entregue
                  no formato 3ds Max, versões 2018 à 2020.
                </p>

                <p class="veto-sans-light"><span class="veto-sans-bold"> ​Render:</span> Formato TIFF, com no mínimo 6000 pixels de largura. </p>

                <p class="veto-sans-light"><span class="veto-sans-bold"> Materiais:</span> Corona Render.</p>

                <p class="veto-sans-light"><span class="veto-sans-bold">Texturas: </span> Formatos jpg, png, TIFF ou TGA. </p>

                <p class="veto-sans-light"> <span class="veto-sans-bold">Plantas Baixas Decoradas:</span> Formato TIFF, com no mínimo 6000 pixels de largura. </p>
              </div>
            </div>
          </div>
          <div class="col-12 mt-3">
            <div class="">
              <PageHeader
                class="titulos-como-funciona veto-sans-bold"
                tag="h4"
                title="Como fazer a entrega de jobs"
                title_color=""
                sub_title=""
                tipo_titulo="titulo-caixa"
              />
              <div class="font-size-15">
                <p class="veto-sans-light"> A entrega dos Jobs é bem simples. </p>
                <p class="veto-sans-light">
                  ​Após a primeira etapa do seu job, você irá renderizar seu modelo em
                  nosso arquivo de estúdio e o enviará junto das imagens em nossa
                  plataforma. Iremos analisar se você fez o job conforme o briefing e
                  os arquivos de referência.​
                </p>
                <p class="veto-sans-light">
                  Se o modelo for entregue muito diferente da referência na primeira
                  revisão, o job pode ser cancelado automaticamente.
                </p>
                <p class="veto-sans-light">
                  ​Faremos uma revisão com tags apontando os erros que devem ser
                  corrigidos. Cada revisão tem um prazo específico e caso você não
                  atenda no tempo correto você automaticamente perde o job.
                </p>
              </div>
            </div>
          </div>
          <div class="col-12 mt-3">
            <div class="">
              <PageHeader
                class="titulos-como-funciona veto-sans-bold"
                tag="h4"
                title="Termos"
                title_color=""
                sub_title=""
                tipo_titulo="titulo-caixa"
              />
              <div class="font-size-15">
                <p class="veto-sans-light">
                  <span class="veto-sans-bold">Job</span> - é o serviço que deve ser executado, vinculado
                  à oferta.
                </p>
                <p class="veto-sans-light"><span class="veto-sans-bold">Oferta</span> - é o job publicado na plataforma.</p>
                <p class="veto-sans-light">
                  <span class="veto-sans-bold">Revisão</span> - Uma etapa de correção do job em um tempo
                  determinado.
                </p>
              </div>
            </div>
          </div>
          <div class="col-12 mt-3">
            <div class="">
              <PageHeader
                class="titulos-como-funciona veto-sans-bold"
                tag="h4"
                title="Job"
                title_color=""
                sub_title=""
                tipo_titulo="titulo-caixa"
              />
              <div class="font-size-15 mt-2">
                <p class="veto-sans-light">
                  Na FullFreela, por enquanto, oferecemos 6 tipos de Jobs, com valores
                  que podem variar de acordo com sua complexidade e prazo.
                </p>
                <p class="veto-sans-light">
                  <span class="veto-sans-bold"> ​Modelo 3D de móveis</span> - modelagem 3d do modelo
                  completo com textura e material.
                </p>
                <p class="veto-sans-light">
                  <span class="veto-sans-bold"> Modelagem 3D arquitetura Externa</span> - modelagem
                  externa 3d do projeto como uma casa ou edifício, os detalhes da
                  entrega como texturas ou materiais serãoo sempre especificado no
                  job.
                </p>
                <p class="veto-sans-light">
                  ​<span class="veto-sans-bold">Modelagem 3D arquitetura Interna</span> - modelagem do
                  ambiente interno, como uma sala ou quarto. É necessário fazer pisos,
                  paredes, esquadrias, forro e quando houver, marcenaria e
                  luminotécnico.os detalhes da entrega como texturas ou materiais
                  serão sempre especificado no job.
                </p>
                <p class="veto-sans-light">
                  ​<span class="veto-sans-bold">Render Interno ou Externo </span> - Usando as plantas
                  baixas, arquivos de paisagismo e decoração, desenvolva o render do
                  começo ao fim, entregando um arquivo TIFF, com um mínimo 6000 pixels
                  na altura ou largura.
                </p>
                <p class="veto-sans-light">
                  <span class="veto-sans-bold">​Implantação</span> - Planta baixa decorada da
                  implantação, seguindo as plantas de arquitetura, paisagismo e
                  decoração. Deverá ser entregue em um arquivo TIFF, com um mínimo
                  6000 pixels de largura.
                </p>
                <p class="veto-sans-light">
                  <span class="veto-sans-bold"> ​Planta Baixa decorada</span> - Planta baixa decorada,
                  podendo ser um apartamento, loja ou casa, seguindo as plantas de
                  arquitetura e decoração. Deverá ser entregue em um arquivo TIFF, com
                  um mínimo 6000 pixels de largura.
                </p>
                <p class="veto-sans-light">
                  No momento, cada freelancer pode fazer um job por vez. Na medida que
                  você completar mais Jobs satisfatoriamente, poderá ter mais
                  trabalhos simultâneos. Por isso sempre entregue o job no prazo, com
                  qualidade e com o mínimo de revisões possíveis.
                </p>
              </div>
            </div>
          </div>
          <div class="col-12 mt-4">
            <div class="">
              <PageHeader
                class="titulos-como-funciona veto-sans-bold"
                tag="h4"
                title="Revisões"
                title_color=""
                sub_title=""
                tipo_titulo="titulo-caixa"
              />
              <div class="font-size-15 mt-2">
                <p class="mb-4 veto-sans-light">
                  São as chances que você tem pra aprovarmos seu job. Cada revisão tem
                  um tempo determinado e caso você não cumpra qualquer um dos prazos,
                  seu job expira automaticamente.
                </p>
                <p class="veto-sans-light">
                  ​Se seu job não for aprovado pelo publicador, o job também é
                  cancelado. O pagamento do Job só é liberado após o aceite e
                  conclusão do Job.
                </p>
                <p class="veto-sans-light">
                  Você terá até três chances para acertar, chamadas de R0, R1 e R2. A
                  R0 é onde você apresenta a primeira versão do modelo ou render, as
                  revisões R1 e R2 apenas para pequenos ajustes finos.
                </p>
                <p class="veto-sans-light">
                  Se o job estiver muito diferente das referências como medidas,
                  plantas baixas e imagens, o job é cancelado já na primeira versão.
                </p>
                <p class="veto-sans-light">
                  ​As revisões estarão atreladas ao job, portanto a cada etapa você
                  pode conferi-las na plataforma.
                </p>
                <p class="veto-sans-light">
                  ​Após finalizar a primeira etapa no caso de modelagem de móveis, o
                  3D Artist irá submeter dois renders feitos em nosso Estúdio virtual,
                  um arquivo 3dsmax com duas câmeras e uma luz pré- configuradas. Você
                  pode baixar o arquivo em nossa plataforma.
                </p>
                <p class="veto-sans-light">
                  ​Já no caso de ser uma imagem completa (Render), Planta Baixa ou
                  Implantação, você enviará uma imagem com 2500 pixels para avaliação.
                </p>
              </div>
            </div>
          </div>
          <div class="col-12 mt-5 mb-5">
            <div class="">
              <div class="font-size-15">
                <p class="veto-sans-light"> ​Se você tiver qualquer dúvida nos envie no e-mail <span class="veto-sans-bold"><a href="mailto:support@fullfreela.com" class="text-dark">support@fullfreela.com </a></span> </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";

export default {
  components: {
    PageHeader
  }
};
</script>

<style>
.titulos-como-funciona h4{
  font-weight: bold;
  padding-right: 10vw;
}
</style>

<template>
  <div
    class="modal fade"
    :id="m_id"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <!-- <h5 class="modal-title" id="exampleModalLabel">Modal title</h5> -->
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <!-- <img :src="this.img" /> -->
          <div class="row row-modal-colors">
            <div class="col-6 cols-modal-colors">
              <canvas
                class="img-responsive"
                id="imgCanvas"
                hidden
                height="388"
                width="100%"
              />

              <img
                id="imageid"
                :src="this.img ? require('../../assets/' + this.img) : false"
                class="img-responsive"
                height="388"
                width="100%"
              />
            </div>
            <div class="col-6 cols-modal-colors">
              <div class=" color-list">
                <div
                  class="color-item mr-1"
                  v-for="(color, index) in Colors()"
                  :key="index"
                  :style="`background:${color.color}`"
                  @click="selectColor(color)"
                >
                  <box-icon
                    class="check-icon h-100"
                    name="check"
                    type="solid"
                    color="#F3A405"
                    v-if="isSelected(color)"
                  >
                  </box-icon>
                  <!--
                  <box-icon
                    class="check-icon"
                    name="check"
                    color="#fff"
                    animation="tada"
                    v-if="isSelected(color)"
                  ></box-icon>
                  <i class='bx bx-check-double bx-flashing' ></i> -->
                </div>
              </div>
              <p class="mt-2 mb-3">Comentario</p>
              <textarea
                class="form-control"
                rows="10"
                v-model="comment"
              ></textarea>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Cancelar
          </button>
          <button
            type="button"
            class="btn btn-primary"
            @click="save()"
            data-dismiss="modal"
          >
            Salvar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import colors from "../../database-json/colors.json";
var fs = require("fs");
export default {
  props: {
    m_id: {
      type: String
    }
  },
  data() {
    return {
      base64: null,
      img: null,
      refId: null,
      selectedColors: [],
      comment: ""
    };
  },
  computed: {
    ...mapGetters(["selectedImage", "roomReferences"])
  },

  mounted() {
    this.$eventHub.$on("refModal", data => {
      this.img = data.src;
      this.refId = data.id;
      this.hasReference();
    });
  },
  methods: {
    ...mapActions(["SET_REFERENCE_TO_ROOM", "REMOVE_REFERENCE_FROM_ROOM"]),
    Colors() {
      return colors;
    },
    selectColor(colors) {
      let haveColor = this.selectedColors.findIndex(color => {
        return color.color === colors.color;
      });
      haveColor > -1
        ? this.selectedColors.splice(haveColor, 1)
        : this.selectedColors.length < 5
        ? this.selectedColors.push(colors)
        : "";
      // return haveColor > -1;
    },
    isSelected(colors) {
      let haveColor = this.selectedColors.findIndex(color => {
        return color.color === colors.color;
      });
      if (haveColor === -1) {
        console.log("menos 1", haveColor);
        return false;
      } else {
        console.log("achou", haveColor);

        return true;
      }
    },
    save() {
      // this.toDataUrl(this.img, myBase64 => {
      //   this.base64 = myBase64; // myBase64 is the base64 string
      // });
      this.base64 = this.toB64();
      setTimeout(() => {
        let data = {
          refId: this.refId,
          image_id: this.selectedImage.id,
          src: this.base64,
          colors: this.selectedColors,
          comment: this.comment
        };
        let ref = this.roomReferences.filter(ref => {
          return ref.image_id === this.selectedImage.id;
        });
        if (ref.length === 0) {
          this.SET_REFERENCE_TO_ROOM(data);
        } else {
          console.log("read this dude,", this.selectedImage.id);
          let roomIndex = this.roomReferences.findIndex(ref => {
            return (
              ref.image_id === this.selectedImage.id && ref.refId === this.refId
            );
          });
          console.log("index foundm,", roomIndex);
          this.REMOVE_REFERENCE_FROM_ROOM(roomIndex);
          this.SET_REFERENCE_TO_ROOM(data);
        }
        this.$eventHub.$emit("4_to_5");
      }, 300);
    },
    hasReference() {
      let ref = this.roomReferences.find(ref => {
        return (
          ref.refId === this.refId && ref.image_id === this.selectedImage.id
        );
      });

      if (ref) {
        this.selectedColors = ref.colors;
        this.comment = ref.comment;
      } else {
        this.selectedColors = [];
        this.comment = "";
      }
    },

    toB64() {
      //tem que ser a tag canvas e tem que estar hidden
      var can = document.getElementById("imgCanvas");
      var img = document.getElementById("imageid");
      var ctx = can.getContext("2d");
      ctx.canvas.width = window.innerWidth;
      ctx.canvas.height = window.innerHeight;
      ctx.drawImage(img, 0, 0, window.innerWidth, window.innerHeight);
      return can.toDataURL();
    }
  }
};
</script>

<style lang="scss">
.activeColor {
  border: 2px blue solid !important;
}
.color-list {
  display: flex;
  flex-wrap: wrap;
  // padding-left: 0;
  .color-item {
    border: 1px #e2e2e2 solid;
    border-radius: 25px;
    display: flex;
    width: 34px;
    height: 34px;
    margin-bottom: 0.3em;
    justify-content: center;
    align-items: center;
    .check-icon {
      font-size: 24px;
      fill: #f6f6f6 !important;
      filter: drop-shadow(0 0 0.1rem RGBA(255,255,255,0.7));
      // stroke: #030303;
      stroke-width: 1;
      stroke-linecap: round;
      stroke-linejoin: round;
    }
  }
}
canvas {
  width: 100%;
  height: 100%;
  position: absolute;
}
</style>
